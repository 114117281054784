import { getLang } from '@/scripts/utils';

import Vue from 'vue';
import store from '@/store';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const languages = ['de', 'en', 'es', 'es-MX', 'pt', 'fr', 'it', 'ko', 'ja', 'zh', 'zh-Hant', 'zh-TW', 'zh-CN', 'zh-SG', 'zh-Hans'];

const routes = [
  {
    path: '/',
    redirect: `/${getLang(languages)}`,
  },
  {
    path: '/:lang',
    name: 'Home',
    component: Home,
  },
  {
    path: '/:lang/:scene(idroscalo|via-chiossetto|galleria-strasburgo)',
    name: 'Scene',
    component: () => import(/* webpackChunkName: "scene-page" */ '../views/Scene.vue'),
  },
  {
    path: '/:lang/:scene(idroscalo)/:game(memory)',
    name: 'GameOne',
    component: () => import(/* webpackChunkName: "game-page" */ '../views/Game.vue'),
  },
  {
    path: '/:lang/:scene(via-chiossetto)/:game(crosswords)',
    name: 'GameTwo',
    component: () => import(/* webpackChunkName: "game-page" */ '../views/Game.vue'),
  },
  {
    path: '/:lang/:scene(galleria-strasburgo)/:game(connects)',
    name: 'GameThree',
    component: () => import(/* webpackChunkName: "game-page" */ '../views/Game.vue'),
  },
  {
    path: '/*',
    component: () => import(/* webpackChunkName: "error-page" */ '../views/404.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const validLang = languages.includes(to.params.lang);
  if (validLang) {
    if (from.params.lang !== to.params.lang) {
      store.commit('SET_LANG', to.params.lang);
    }
    next();
  } else {
    const lang = getLang(languages);
    store.commit('SET_LANG', lang);
    next(`/${lang}`);
  }
});

router.afterEach((to) => {
  window.ga('set', 'page', to.path);
  window.ga('send', 'pageview');
});

export default router;
