export default {
  meta: {
    title: 'El Enigma Metropolitano - Salvatore Ferragamo',
    description: 'Resuelva el Enigma de Salvatore Ferragamo y descubra sorpresas especiales',
  },

  header: {
    back: 'Volver a la página de inicio',
    collection: 'Ver SS21',
  },

  nextGameCta: 'Jugar el siguiente juego',
  endGameCta: 'Resuelve el último enigma',

  CookieBar: {
    text: 'Ferragamo.com utiliza cookies propias y de terceros para garantizar el correcto funcionamiento del sitio web y, con su consentimiento, con fines estadísticos y para brindarle una experiencia personalizada, así como para enviarle comunicaciones publicitarias. Para obtener más información y cambiar sus preferencias, haga clic en el botón "Administrar opciones".',
    manage_options: 'ADMINISTRAR OPCIONES',
    accept: 'ACEPTO',
    code: 'es_31151',
    link: 'https://www.ferragamo.com/shop/esp/es/sf/legal/cookie-policy#cookieSettings',
  },

  loader: {
    titleText: {
      title: 'El Enigma Metropolitano',
      text: 'Resuelva el Enigma de Salvatore Ferragamo y descubra sorpresas especiales.<br>Juega con nosotros en las calles de Milán, el escenario del exclusivo fashion film PV21 dirigido por el aclamado director italiano Luca Guadagnino en colaboración con nuestro director creativo Paul Andrew.',
    },
    cta: 'Inicia la investigación',
  },

  idroscalo: {
    titleText: {
      title: 'Parco <br class="only-mobile"> dell’Idroscalo',
      text: 'Para resolver cualquier misterio, es fundamental saber qué se está buscando.<br>¡Es aconsejable recopilar pistas y descubrirlo!',
    },
    cta: 'Empiece a resolver',
    nextLink: 'idroscalo/memory',
  },

  memory: {
    inGame: '¡La memoria y la intuición pura son las habilidades de un verdadero detective!',
    titleText: {
      title: '¡Memoria excelente!',
      text: 'Estás un paso más cerca de resolver el Enigma.',
    },
    nextLink: 'via-chiossetto',
  },

  'via-chiossetto': {
    titleText: {
      title: 'Via <br class="only-mobile"> Chiossetto',
      text: 'Interrogar sospechosos es mucho mas complicado de lo que podría esperar. <br> ¡Es tiempo de ver si puede hacerlo!',
    },
    cta: 'Busca las respuestas',
    nextLink: 'via-chiossetto/crosswords',
  },

  crosswords: {
    inGame: 'Es importante hacer las preguntas correctas, ¡pero aún más buscar las respuestas correctas!',
    letters: [
      ['T', 'B', 'S', 'Y', 'B', 'E', 'O', 'E', 'D', 'W', 'U'],
      ['E', 'N', 'V', 'E', 'N', 'I', 'G', 'M', 'A', 'N', 'J'],
      ['C', 'C', 'G', 'B', 'C', 'C', 'V', 'N', 'I', 'K', 'B'],
      ['H', 'Z', 'I', 'W', 'Y', 'C', 'B', 'Z', 'E', 'Q', 'C'],
      ['N', 'T', 'V', 'A', 'S', 'M', 'V', 'B', 'J', 'X', 'E'],
      ['I', 'E', 'J', 'A', 'Z', 'G', 'X', 'L', 'I', 'F', 'E'],
      ['C', 'R', 'U', 'S', 'R', 'P', 'V', 'M', 'W', 'P', 'F'],
      ['O', 'B', 'X', 'Y', 'F', 'F', 'G', 'G', 'V', 'P', 'I'],
      ['L', 'P', 'Z', 'B', 'E', 'A', 'U', 'T', 'Y', 'O', 'C'],
      ['O', 'J', 'B', 'Q', 'C', 'F', 'P', 'H', 'K', 'U', 'H'],
      ['R', 'F', 'K', 'Y', 'E', 'W', 'G', 'A', 'H', 'V', 'S'],
    ],
    words: ['Life', 'Enigma', 'Beauty', 'Technicolor'],
    titleText: {
      title: 'Eres un detective sagaz',
      text: 'Estás un paso más cerca de resolver el Enigma.',
    },
    nextLink: 'galleria-strasburgo',
  },

  'galleria-strasburgo': {
    titleText: {
      title: 'Galleria <br class="only-mobile"> Strasburgo',
      text: 'Un buen detective debería ser capaz de conectar las pistas.',
    },
    cta: 'Persigue al sospechoso',
    nextLink: 'galleria-strasburgo/connects',
  },

  connects: {
    tutorial: 'Arrastra el Gancini y gira el escenario para conectar las dos partes',
    inGame: '¡Gira el mapa a tu favor y atrapa al sospechoso!',
    titleText: {
      title: '¡Siempre es bueno jugar inteligentemente!',
      text: 'Estás un paso más cerca de resolver el Enigma.',
    },
    nextLink: 'idroscalo',
  },

  'end-game': {
    titleText: {
      title: 'Hay un último enigma por resolver...',
      text: '¡El éxito está a la vuelta de la esquina!<br>¡Sigue el enlace para resolver el último enigma y reclamar tus sorpresas!',
    },
    nextLink: 'https://www.ferragamo.com/enigma',
  },

  404: {
    message: ' Algo salió mal',
    cta: 'Volver a la página de inicio',
  },

  rotate: {
    message: 'Gira tu dispositivo',
    thanks: 'Gracias',
  },
};
