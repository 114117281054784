<template>
  <div class="frg17_overlayCookies cc-customdialog" style="display:none">
    <p>{{ $translate('CookieBar').text }}</p>
    <a
      class="frg17_closeCookies cc-button-enableall cc_cd_lbl_button_close btn cmp-button cmp-button--md cmp-button--fillblack"
      href="#"
      qa-id="privacy-dismiss-button"
    >
      {{ $translate('CookieBar').accept }}
    </a>
    <a
      class="frg17_refuseCookies cc-button-refuseall cc_cd_lbl_button_close btn cmp-button cmp-button--md cmp-button--fillblack"
      :href="$translate('CookieBar').link"
      target="_blank"
      rel="noopener noreferrer"
      qa-id="privacy-dismiss-button"
    >
      {{ $translate('CookieBar').manage_options }}
    </a>
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  name: 'CookieBar',

  async mounted() {
    window.$ = $;
    window.jQuery = $;

    const ccSettings = {};
    if (document.getElementsByName('cookie_page').length > 0 && document.getElementsByName('cookie_page')[0].value === 'true') {
      ccSettings.settings = {
        messagetype: 'none',
        consenttype: 'hybrid-allenabled',
      };
    } else {
      ccSettings.settings = {
        privacypage: 'https://www.ferragamo.com/cookie-policy',
        locale: this.$translate('CookieBar').code,
        disableClickTrigger: false,
      };
    }

    window.ccSettings = ccSettings;

    await require('@/scripts/cookie/cookie-plugin');
    await require('@/scripts/cookie/tcl_cookieconsent_i18n');
    await require('@/scripts/cookie/tcl_cookieconsent');
  },
};
</script>

<style lang="scss" scoped>
.frg17_grid_row_overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100000;
  cursor: pointer;
}

.frg17_overlayCookies {
  position: fixed;
  bottom: 25px;
  right: 30px;
  padding: 20px;
  max-width: 377px;

  //border: solid 1px #000;

  html[lang*="es"] & {
    max-width: 412px;
  }

  html[lang="de"] & {
    max-width: 412px;
  }

  background-color: #645F5D;
  //border: solid 1px #000;

  p {
    position: relative;
    margin: 0;
    color: #fff;
    font-size: 12px;
    line-height: 16px;

    &:after {
      content: '';
      display: block;
      width: 100%;
      margin: 25px 0;
    }
  }

  .cc-link {
    color: #000;
  }

  .frg17_closeCookies {
    float: right;
    background: #534f4d;
    border: solid 1px #534f4d;
    color: #fff;
    padding: 13px 15px;
    font-size: 13px;
    text-transform: uppercase;
    min-width: 158px;
    min-height: 44px;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    font-weight: 600;

    transition-property: all;
    transition-duration: .5s;
    transition-delay: 0s;
    transition-timing-function: ease-in-out;

    &:hover {
      background: #d6d6d6;
      color: #000;
      border-color: #d6d6d6;

      transition-property: color,border-color,background-color;
      transition-duration: .5s;
      transition-delay: 0s;
      transition-timing-function: ease-in-out;
    }
  }

  .frg17_refuseCookies {
    float: left;
    background: #534f4d;
    border: solid 1px #534f4d;
    color: #fff;
    padding: 13px 15px;
    font-size: 13px;
    text-transform: uppercase;
    min-width: 158px;
    min-height: 44px;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    font-weight: 600;

    transition-property: all;
    transition-duration: .5s;
    transition-delay: 0s;
    transition-timing-function: ease-in-out;

    &:hover {
      background: #d6d6d6;
      color: #000;
      border-color: #d6d6d6;

      transition-property: color,border-color,background-color;
      transition-duration: .5s;
      transition-delay: 0s;
      transition-timing-function: ease-in-out;
    }
  }
}

//@media screen and (max-width: @screen-xs-max) {
@media screen and (max-width: 767px) {
  .frg17_overlayCookies {
    left: 30px;
    display: grid;
  }

  .frg17_closeCookies {
    margin-bottom: 10px;
  }
}
</style>
