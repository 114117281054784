import EventBus from './eventBus';

const screen = {
  x: window.innerWidth,
  y: window.innerHeight,
  unitVw: window.innerWidth >= 768 ? 3.125 : 4,
  ratio: {
    x: window.innerWidth / window.innerHeight,
    y: window.innerHeight / window.innerWidth,
  },
  orientation: window.innerWidth < 768 ? 'portrait' : 'landscape',

  resize() {
    screen.x = window.innerWidth;
    screen.y = window.innerHeight;
    screen.unitVw = screen.x >= 768 ? 3.125 : 4;
    screen.ratio.x = screen.x / screen.y;
    screen.ratio.y = screen.y / screen.x;

    const currentOrientation = screen.x < 768 ? 'portrait' : 'landscape';
    if (currentOrientation !== screen.orientation) {
      screen.orientation = currentOrientation;
      EventBus.$emit('window-orientation-changed');
    }
  },
};

export default screen;
