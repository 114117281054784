<template>
  <div class="cards">
    <CardsItem
      v-for="(card, i) in cards"
      :key="i"
      :data="card"
      @mouseenter.native="mouseenterHandler(i)"
      @mouseleave.native="mouseleaveHandler()"
      @click.native="clickHandler(card)"
    />
  </div>
</template>

<script>
import CardsItem from '@/components/CardsItem';
import CardsEngine from '@/scripts/CardsEngine';

export default {
  name: 'Cards',

  components: {
    CardsItem,
  },

  data() {
    return {
      cards: [
        {
          id: 'idroscalo',
          game: 'memory',
          cta: 'Parco dell\'Idroscalo',
          bgColor: '#576F92',
          mapColor: '#6583AC',
        },
        {
          id: 'galleria-strasburgo',
          game: 'connects',
          cta: 'Galleria Strasburgo',
          bgColor: '#E3C873',
          mapColor: '#E3D196',
        },
        {
          id: 'via-chiossetto',
          game: 'crosswords',
          cta: 'Via Chiossetto',
          bgColor: '#8C8E62',
          mapColor: '#929466',
        },
      ],
      clicked: false,
      timeout: null,
    };
  },

  mounted() {
    CardsEngine.init(this.$el, this.$audio);
  },

  methods: {
    mouseenterHandler(i) {
      if (this.$isMobile) return;
      if (this.$store.state.cardsEvenetDisable) return;
      if (this.timeout) {
        window.clearTimeout(this.timeout);
        this.timeout = null;
      }
      CardsEngine.toHover(i);
    },
    mouseleaveHandler() {
      if (this.$isMobile) return;
      if (this.$store.state.cardsEvenetDisable) return;
      if (!this.timeout) this.timeout = window.setTimeout(() => CardsEngine.toHome(true, this.$audio), 400);
    },
    clickHandler(data) {
      if (this.$store.state.cardsEvenetDisable) return;
      this.$audio.play('click');
      this.$store.commit('TOGGLE_CARDS_EVENTS');
      this.$sendEvent('Select game', data.id);

      this.$router.push(`/${this.$route.params.lang}/${data.id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.cards {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 1px;
  height: 1px;
}
</style>
