<template>
  <a
    class="b-collection-button collection-button"
    href="https://www.ferragamo.com/what-s-new"
    target="_blank"
    rel="noopener noreferrer"
    @click="onClick"
    aria-label="Link to collection"
  >
    {{ $translate('header').collection }}

    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 16.9 16.9">
      <g class="arrow" fill="none" stroke="#fff" stroke-width="1px">
        <path d="M14.5,11.6c-2.5-2.6-2.5-6.7,0.1-9.2c0,0,0,0,0,0C12,5,7.8,5,5.3,2.4c0,0,0,0,0,0"/>
        <line x1="14.3" y1="2.6" x2="2.4" y2="14.5"/>
      </g>
    </svg>
  </a>
</template>

<script>
export default {
  name: 'BaseCollectionButton',

  props: {
    top: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    onClick() {
      this.$audio.play('click');
      this.$sendEvent('Click on View SS21');
    },
  },
};
</script>

<style lang="scss" scoped>
.collection-button {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  svg {
    height: 100%;
    width: grid-size(0.3);
  }

  @include mq($until: ipadP) {
    svg {
      width: grid-size(0.75);
    }
  }

  @include mq($and: $is-not-touch-device) {
    svg {
      transition: transform 0.5s $ease-base;
    }

    &:hover svg {
      transform: translate(2px, -2px);
    }
  }
}
</style>
