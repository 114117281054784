/* eslint-disable object-curly-newline */
import gsap from 'gsap';
import { fadeLeave } from './fade';
import tutorialLeave from './tutorial';

let stageStaticLines;
let lines;
let linesMovable;
let squares;
let bear;
let heart;
let explosion;
let tutorial;

export function connectsInit(el) {
  stageStaticLines = el.querySelector('.game__stage--static-line');
  lines = el.querySelectorAll('.game__static-line');
  linesMovable = el.querySelectorAll('.game__movable-line');
  squares = el.querySelectorAll('.game__dot');
  bear = el.querySelector('.game__bear');
  heart = el.querySelector('.game__heart');
  explosion = el.querySelector('.game__explosion');
  tutorial = el.querySelector('.game__stage--tutorial');

  [...lines, ...linesMovable].forEach((line) => {
    if (line.hasAttribute('data-vertical')) {
      gsap.set(line, { opacity: 0 });
    } else {
      gsap.set(line, { opacity: 0 });
    }
  });

  gsap.set([bear, heart, ...squares], { opacity: 0, scale: 1.5 });
  gsap.set(tutorial, { autoAlpha: 0 });
  gsap.set(stageStaticLines, { backgroundColor: 'rgba(0, 0, 0, 0)' });

  gsap.set(explosion, { scale: 0 });
}

export function connectsAnim() {
  const tl = gsap.timeline();

  tl.to(squares, {
    opacity: 1,
    scale: 1,
    duration: 1,
    ease: 'power2.inOut',
    stagger: {
      each: 0.1,
      from: 'random',
    },
  }, 0)
    .to(lines, {
      opacity: 1,
      duration: 1,
      ease: 'power2.inOut',
    }, 0.25)
    .to([bear, heart], {
      opacity: 1,
      scale: 1,
      duration: 1,
      ease: 'power2.inOut',
      stagger: {
        each: 0.2,
        from: 'random',
      },
    }, 0.5);

  if (linesMovable.length > 0) {
    tl.to(linesMovable, {
      opacity: 1,
      scaleX: 1,
      scaleY: 1,
      duration: 1,
      ease: 'power2.inOut',
      stagger: {
        each: 0.2,
        from: 'random',
      },
    }, 0.25);
  }

  tl.add(tutorialLeave('one', stageStaticLines, tutorial));

  return tl;
}

function getBearAngle() {
  const { top: bearTop, right: bearRight, bottom: bearBottom, left: bearLeft } = bear.getBoundingClientRect();
  const { top: heartTop, right: heartRight, bottom: heartBottom, left: heartLeft } = heart.getBoundingClientRect();

  const p1 = { x: (bearRight + bearLeft) / 2, y: (bearBottom + bearTop) / 2 };
  const p2 = { x: (heartRight + heartLeft) / 2, y: (heartBottom + heartTop) / 2 };

  const dx = p2.x - p1.x;
  const dy = p2.y - p1.y;
  const radAngle = Math.atan2(dy, dx);
  return radAngle * (180 / Math.PI) + 90;
}

export function connectsOut(el) {
  // get bear angle
  /* eslint-disable object-curly-newline */
  const bearAngle = getBearAngle();

  gsap.set(bear, { rotate: bearAngle + 90 });
  gsap.set(heart, { rotate: bearAngle + 90 });

  const text = el.querySelector('.game__footer');

  const { left: bearLeft, right: bearRight, top: bearTop, bottom: bearBottom } = bear.getBoundingClientRect();
  const { left: heartLeft, right: heartRight, top: heartTop, bottom: heartBottom } = heart.getBoundingClientRect();
  const bearSize = {
    width: bearRight - bearLeft,
    height: bearBottom - bearTop,
  };
  const bearCenter = {
    x: (bearRight - bearLeft) / 2,
    y: (bearBottom - bearTop) / 2,
  };
  const bearCenterPosition = {
    x: bearCenter.x + bearLeft,
    y: bearCenter.y + bearTop,
  };

  const heartCenter = {
    x: (heartRight - heartLeft) / 2,
    y: (heartBottom - heartTop) / 2,
  };
  const heartCenterPosition = {
    x: heartCenter.x + heartLeft,
    y: heartCenter.y + heartTop,
  };

  const bearDistance = {
    x: heartCenterPosition.x - bearCenterPosition.x,
    y: heartCenterPosition.y - bearCenterPosition.y,
  };

  bearDistance.x = Math.trunc(bearDistance.x);
  bearDistance.y = Math.trunc(bearDistance.y);

  const bearTransition = {
    x: bearDistance.x,
    y: bearDistance.y === 0 ? bearDistance.y : bearDistance.y + bearCenter.y + heartCenter.y,
  };

  bearTransition.x = Math.trunc(bearTransition.x);
  bearTransition.y = Math.trunc(bearTransition.y);

  const explosionTransition = {
    x: 0,
    y: 0,
  };

  if (bearTransition.x !== 0) {
    if (bearTransition.x > 0) {
      bearTransition.x = bearDistance.x - bearCenter.x - heartCenter.x + (bearSize.width * 0.2);
      explosionTransition.x = '-25%';
    } else {
      bearTransition.x = bearDistance.x + bearCenter.x + heartCenter.x - (bearSize.width * 0.2);
      explosionTransition.x = '25%';
    }
  }

  if (bearTransition.y !== 0) {
    if (bearTransition.y > 0) {
      bearTransition.y = bearDistance.y - bearCenter.y - heartCenter.y + (bearSize.height * 0.2);
      explosionTransition.y = '-25%';
    } else {
      bearTransition.y = bearDistance.y + bearCenter.y + heartCenter.y - (bearSize.height * 0.2);
      explosionTransition.y = '25%';
    }
  }

  const tl = gsap.timeline();

  tl.to(bear, {
    x: bearTransition.x,
    y: bearTransition.y,
    duration: 0.4,
    ease: 'power2.inOut',
  })
    .set(explosion, {
      x: explosionTransition.x,
      y: explosionTransition.y,
    })
    .to(explosion, {
      scale: 2.6,
      duration: 0.4,
      ease: 'power2.inOut',
    }, '-=0.2')
    .to(squares, {
      scale: 0,
      duration: 1,
      ease: 'power2.in',
      stagger: {
        each: 0.1,
        from: 'random',
      },
    }, '-=0.3')
    .to(el, {
      opacity: 0,
      duration: 0.8,
      ease: 'power1.inOut',
    });

  if (text) tl.add(fadeLeave(text));

  return tl;
}

export function levelOut(el) {
  return gsap.timeline()
    .to([el], {
      opacity: 0,
      duration: 1,
      ease: 'sine.inOut',
    });
}

export function levelIn(el) {
  return gsap.timeline()
    .from(el, {
      opacity: 0,
      duration: 0.8,
      ease: 'power1.inOut',
    });
}
