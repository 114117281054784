import Apollo from '@adoratorio/apollo';

const cursor = {
  ref: null,
  prevNodes: [],

  init(el, engine) {
    cursor.ref = new Apollo({
      cursor: el,
      easing: {
        mode: Apollo.EASING.CUBIC,
        duration: 400,
      },
      aion: engine,
    });
  },
};

export default cursor;
