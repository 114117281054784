<template>
  <div class="c-app-rotate-device rotate-device">
    <img class="rotate-device__logo" width="600.1" height="113.6" src="/assets/svg/salvatore-ferragamo-logo.svg" alt="Logo Salvatore Ferragamo">
    <span class="rotate-device__message">{{ $translate('rotate').message }}</span>
    <span class="rotate-device__thanks">{{ $translate('rotate').thanks }}</span>
  </div>
</template>

<script>
export default {
  name: 'AppRotateDevice',
};
</script>

<style lang="scss" scoped>
.rotate-device {
  @include full(true);
  position: relative;

  background-color: $color-background;

  @supports (background-blend-mode: multiply) {
    background-blend-mode: multiply;
    background-image: url(/assets/img/base/shadow-game.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  z-index: 999;

  display: none;
  align-items: center;
  justify-content: center;

  @include mq($until: ipadL, $and: '(orientation: landscape)') {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  @include mq($and: $is-not-touch-device) {
    display: none;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 50vw;
    height: 100%;
    top: 0;

    background-size: contain;
    background-repeat: no-repeat;

    z-index: -1;
  }

  &:before {
    left: 0;
    right: auto;
    background-image: url(/assets/img/illustrations/crosswords_00.png);
    background-position: left center;
  }
  &:after {
    left: auto;
    right: 0;
    background-image: url(/assets/img/illustrations/connects_00.png);
    background-position: left center;
    transform: rotateY(180deg);
  }

  &__logo {
    width: 30vw;
    flex-basis: 45%;
    flex-shrink: 0;
    padding-top: grid-size(2);

    object-fit: contain;
    object-position: top;
  }

  &__message {
    font-family: $font-family-heading;
    font-size: 5vw;
    margin-bottom: grid-size(1);
  }

  &__thanks {
    font-family: $font-family-heading;
    font-size: 2vw;
  }
}
</style>
