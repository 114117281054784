import { gsap } from 'gsap';
import Stage from '@/stage';
import CardsEngine from '../CardsEngine';
import { leaveBlockText, enterBlockText } from './blockText';
import { init } from '../animations';

export function setGame(el) {
  const { game } = el.dataset;
  const text = el.querySelector('.game__footer');

  init.get(game)(el);
  init.get('fade')(text);
}

export function gameToHome(from) {
  const tl = gsap.timeline();
  const win = from.querySelector('.game__win');

  tl.addLabel('start')
    .add(() => CardsEngine.toHome(), 'start');

  if (win) tl.add(leaveBlockText(win), 'start');
  else tl.to(from, { autoAlpha: 0, duration: 0.6 }, 'start');

  return tl;
}

export function gameToScene(from, to) {
  const tl = gsap.timeline();
  const win = from.querySelector('.game__win');
  const { scene } = to.dataset;

  tl.addLabel('start')
    .add(() => CardsEngine.hide(), 'start+=0.35')
    .add(() => Stage.animate(scene, 'enter'), 'start+=0.5')
    .add(enterBlockText(to), 'start+=1');

  if (win) tl.add(leaveBlockText(win), 'start');
  else tl.to(from, { autoAlpha: 0, duration: 0.6 }, 'start');

  return tl;
}
