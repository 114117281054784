<template>
<div class="cta-container">
  <button v-if="to === '' && href === ''" class="b-cta cta" data-label="generic cta">
    <p v-html="content" />
  </button>
  <a v-else-if="to === ''"
    :href="href"
    target="_blank"
    class="b-cta cta"
    @click="onClick"
    :data-label="`link to ${content}`"
  >
    <p v-html="content" />
  </a>
  <router-link v-else :to="to" class="b-cta cta" :data-label="`link to ${content}`">
    <p v-html="content" />
  </router-link>
</div>
</template>

<script>
export default {
  name: 'BaseCta',

  props: {
    content: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: false,
      default: '',
    },
    href: {
      type: String,
      required: false,
      default: '',
    },
  },

  computed: {
    gameEnded() {
      const endedArray = Object.values(this.$store.state.gameEnded);
      return endedArray.every((game) => game);
    },
  },

  methods: {
    onClick() {
      if (this.gameEnded) {
        this.$sendEvent('Go to Commerce');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cta-container {
  white-space: nowrap;
  position: fixed;
  bottom: grid-size(7);
  left: 50%;
  padding: 0;

  transform: translateX(-50%);

  display: flex;
  align-items: flex-start;
  justify-content: center;

  @include mq(ipadP) {
    bottom: grid-size(3);
  }
}

.cta {
  padding: 0;

  pointer-events: auto;
  cursor: pointer;

  @include bottom-line();

  p {
    font-weight: 500;
    text-transform: uppercase;
  }
}
</style>
