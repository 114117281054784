import { gsap } from 'gsap';
import CustomEase from '@/assets/libs/CustomEase'; // eslint-disable-line
import screen from '@/scripts/screen';
import { isIOS } from '@/scripts/utils';
import EventBus from './eventBus';

gsap.registerPlugin(CustomEase);

class CardsItem {
  constructor(el, data) {
    this.$el = el;
    this.id = data.id;
    this.index = data.index;
    this.positions = data.positions;
    this.bgColor = data.bgColor;
    this.mapColor = data.mapColor;
    this.completeColor = '#B8B4E2';
    this.nextCard = data.nextCard;
    this.phase = 'out';

    this.gameCompleted = false;
    this.inTransition = false;
    this.imgHidden = false;
    this.imgContainer = el.querySelector('.card__images-container');
    this.text = el.querySelector('.card__text');

    this.init();
    this.addEvents();
  }

  addEvents() {
    EventBus.$on(`${this.id}-enter`, () => this.hoverEnter());
    EventBus.$on(`${this.id}-leave`, () => this.hoverLeave());
  }

  getCurrentPhase() {
    return this.positions[screen.orientation][this.phase];
  }

  loadPicture(image) {
    const { src } = image.dataset;
    const errorHandler = () => new Error(`Failed to load image's URL: ${src}`);
    const promiseFunction = (resolve) => {
      if (typeof image.decode !== 'undefined') {
        image.decoding = 'sync';
        image.src = src;
        image.decode().then(() => {
          if (typeof window.requestIdleCallback !== 'undefined') {
            requestAnimationFrame(() => requestIdleCallback(resolve));
          } else {
            requestAnimationFrame(() => requestAnimationFrame(resolve));
          }
        });
      } else {
        const imageHandler = () => {
          image.removeEventListener('load', imageHandler);
          image.removeEventListener('error', errorHandler);
          image.remove();

          resolve();
        };

        image.addEventListener('load', imageHandler());
        image.addEventListener('error', errorHandler());
      }
    };

    image.src = src;

    return new Promise(promiseFunction);
  }

  load() {
    const imgs = [...this.$el.querySelectorAll('.card__image')];
    const promises = [];

    imgs.forEach((img) => promises.push(this.loadPicture(img)));

    return Promise.all(promises);
  }

  shortLoad() {
    const imgs = [...this.$el.querySelectorAll('.card__image')];

    imgs.forEach((img) => { img.src = img.dataset.src; });
  }

  init() {
    this.imgHidden = true;
    gsap.set(this.$el, {
      '--translateX': `${this.getCurrentPhase().x}vw`,
      '--translateY': `${this.getCurrentPhase().y}vh`,
      autoAlpha: 0,
      '--rotateZ': `${this.getCurrentPhase().rZ}deg`,
      pointerEvents: 'none',
    });
    gsap.set(this.imgContainer, { autoAlpha: 0 });
    gsap.set(this.text, { autoAlpha: 0 });

    this.load().then(() => this.toLoader());
  }

  revealImages() {
    this.imgHidden = false;

    gsap.to(this.imgContainer, { autoAlpha: 1, duration: 1.2 });
    gsap.to(this.text, { autoAlpha: 1, duration: 1.2 });
  }

  toLoader() {
    this.phase = 'loader';

    gsap.to(this.$el, {
      '--translateX': `${this.getCurrentPhase().x}vw`,
      '--translateY': `${this.getCurrentPhase().y}vh`,
      '--rotateZ': `${this.getCurrentPhase().rZ}deg`,
      autoAlpha: 1,
      duration: 1,
      pointerEvents: 'none',
      ease: CustomEase.create('custom', 'M0,0 C0.48,0 0.612,0.454 0.64,0.638 0.679,0.898 0.818,1.001 1,1'),
      delay: this.index * 0.05,
    });
  }

  hide() {
    if (this.phase === 'out') return;
    const prevPhase = this.phase;
    this.phase = 'out';

    // eslint-disable-next-line consistent-return
    return gsap.to(this.$el, {
      '--translateX': prevPhase === 'toNext' ? 0 : `${this.getCurrentPhase().x}vw`,
      '--translateY': prevPhase === 'toNext' ? '100vh' : `${this.getCurrentPhase().y}vh`,
      '--rotateZ': prevPhase === 'toNext' ? 0 : `${this.getCurrentPhase().rZ}deg`,
      autoAlpha: 0,
      duration: 1,
      pointerEvents: 'none',
      ease: CustomEase.create('custom', 'M0,0 C0.48,0 0.612,0.454 0.64,0.638 0.679,0.898 0.818,1.001 1,1'),
      delay: this.index * 0.05,
    });
  }

  toHome(fast, endGame = false) {
    if (this.inTransition) return;
    this.inTransition = true;
    this.phase = 'index';

    if (endGame) {
      gsap.set(this.$el, { backgroundColor: this.bgColor });
      gsap.set(this.$el.querySelector('.card__images-container'), { backgroundColor: this.mapColor });
      gsap.set(this.$el.querySelector('.card__image--selection'), { opacity: 1 });
    }

    gsap.killTweensOf(this.$el);
    gsap.to(this.$el, {
      '--translateX': `${this.getCurrentPhase().x}vw`,
      '--translateY': `${this.getCurrentPhase().y}vh`,
      '--rotateZ': `${this.getCurrentPhase().rZ}deg`,
      autoAlpha: 1,
      duration: fast ? 0.6 : 1.6,
      ease: fast ? 'power2.inOut' : CustomEase.create('custom', 'M0,0 C0.48,0 0.612,0.454 0.64,0.638 0.679,0.898 0.818,1.001 1,1'),
      onComplete: () => {
        this.inTransition = false;
        gsap.set(this.$el, { pointerEvents: 'auto' });
      },
    });

    if (this.imgHidden) this.revealImages();
  }

  toHover(i) {
    this.inTransition = false;
    this.phase = 'hover';

    gsap.killTweensOf(this.$el);
    gsap.to(this.$el, {
      '--translateX': `${this.getCurrentPhase()[i].x}vw`,
      '--translateY': `${this.getCurrentPhase()[i].y}vh`,
      '--rotateZ': `${this.getCurrentPhase()[i].rZ}deg`,
      pointerEvents: 'auto',
      duration: 0.6,
      ease: CustomEase.create('custom', 'M0,0 C0.48,0 0.612,0.454 0.64,0.638 0.679,0.898 0.818,1.001 1,1'),
    });
  }

  toScene(i) {
    this.inTransition = false;
    this.phase = 'hoverOut';
    const tl = gsap.timeline();

    gsap.killTweensOf(this.$el);

    tl.addLabel('start');
    tl.to(this.$el, {
      '--translateX': `${this.getCurrentPhase()[i].x}vw`,
      '--translateY': `${this.getCurrentPhase()[i].y}vh`,
      '--rotateZ': `${this.getCurrentPhase()[i].rZ}deg`,
      autoAlpha: i === this.index ? 1 : 0,
      duration: i === this.index ? 0.6 : 2,
      pointerEvents: 'none',
      ease: 'power3.out',
    });

    if (i === this.index) {
      tl.to(this.$el, {
        '--translateY': '100vh',
        autoAlpha: 0,
        duration: 1.2,
        ease: CustomEase.create('custom', 'M0,0 C0.48,0 0.612,0.454 0.64,0.638 0.679,0.898 0.818,1.001 1,1'),
      }, 'start+=0.6');
    }
  }

  toNext(endGame) {
    this.phase = 'toNext';

    if (endGame) {
      gsap.set(this.$el, { backgroundColor: this.completeColor });
      gsap.set(this.$el.querySelector('.card__images-container'), { backgroundColor: this.completeColor });
      gsap.set(this.$el.querySelector('.card__image--selection'), { opacity: 0 });
    }

    if (this.imgHidden) this.revealImages();

    gsap.fromTo(this.$el, {
      '--translateY': '100vh',
      '--translateX': 0,
      '--rotateZ': 45,
      autoAlpha: 0,
    }, {
      '--translateY': isIOS() ? '53vh' : '60vh',
      '--translateX': 0,
      '--rotateZ': 5,
      autoAlpha: 1,
      duration: 1.2,
      ease: CustomEase.create('custom', 'M0,0 C0.48,0 0.612,0.454 0.64,0.638 0.679,0.898 0.818,1.001 1,1'),
    });
  }

  hoverEnter() {
    if (this.phase === 'out') return;

    gsap.to(this.$el, {
      '--rotateZ': 0,
      duration: 0.6,
      ease: CustomEase.create('custom', 'M0,0 C0.48,0 0.612,0.454 0.64,0.638 0.679,0.898 0.818,1.001 1,1'),
    });
  }

  hoverLeave() {
    if (this.phase === 'out') return;

    gsap.to(this.$el, {
      '--rotateZ': 5,
      duration: 0.6,
      ease: CustomEase.create('custom', 'M0,0 C0.48,0 0.612,0.454 0.64,0.638 0.679,0.898 0.818,1.001 1,1'),
    });
  }
}

export default CardsItem;
