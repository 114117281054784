<template>
  <button
    class="app-toggle-sound toggle-sound"
    :class="{ 'toggle-sound--pause' : !$store.state.playSound }"
    @click="$store.commit('TOGGLE_SOUND')"
    aria-label="Toggle sound button"
  >
    <span class="toggle-sound__line" />
    <span class="toggle-sound__line" />
    <span class="toggle-sound__line" />
    <span class="toggle-sound__line" />
    <span class="toggle-sound__line" />
  </button>
</template>

<script>
export default {
  name: 'AppToggleSound',

  computed: {
    sound() {
      return this.$store.state.playSound;
    },
  },

  watch: {
    sound(newValue) {
      if (newValue) {
        this.$audio.unmute();
      } else {
        this.$audio.mute();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes sound {
  0% {
    opacity: .35;
    transform: scaleY(0.1);
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}

.toggle-sound {
  position: absolute;
  left: auto;
  right: grid-size(1);
  bottom: grid-size(1);

  width: 35px;
  height: 29px;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  &__line {
    position: relative;

    width: 2px;

    background-color: $color-white;

    animation: sound 0ms -800ms linear infinite alternate;

    &:nth-child(odd) {
      height: 8px;
      animation-duration: 874ms;
    }
    &:nth-child(even) {
      height: 10px;
      margin: 0 2px;
      animation-duration: 833ms;
    }
    &:nth-child(3) {
      height: 13px;
      animation-duration: 827ms;
    }
  }

  &.toggle-sound--pause .toggle-sound__line {
    animation-play-state: paused;
  }
}
</style>
