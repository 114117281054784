import { gsap } from 'gsap';
import SplitText from '@/assets/libs/SplitText';

const generateAnimation = (els, dir = 'enter') => gsap.to(els, {
  yPercent: dir === 'enter' ? 0 : -50,
  autoAlpha: dir === 'enter' ? 1 : 0,
  duration: dir === 'enter' ? 1.2 : 0.8,
  stagger: {
    from: 'random',
    ease: 'linear',
    amount: 0.5,
  },
  ease: 'power2.inOut',
});

export function titleInit(el) {
  const split = new SplitText(el, { type: 'chars, words', charsClass: 'c-split-char' });

  gsap.set(split.chars, { yPercent: 50, autoAlpha: 0 });
}

export function titleEnter(el) {
  const chars = [...el.querySelectorAll('.c-split-char')];

  return generateAnimation(chars);
}

export function titleLeave(el) {
  const chars = [...el.querySelectorAll('.c-split-char')];

  return generateAnimation(chars, 'leave');
}
