/* eslint-disable */
/*
 Copyright 2015 Tecla srl
 Copyright 2012-2013 Silktide Ltd.

 This program is free software: you can redistribute it and/or modify
 it under the terms of the GNU General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 This program is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU General Public License for more details.

 You should have received a copy of the GNU General Public License
 along with this program.  If not, see <http://www.gnu.org/licenses/>
*/

window.dataLayer = window.dataLayer || [];

var _gaq = _gaq || [];
//var BrTrk = {getTracker:function(a,b,c,d){return{logEvent:function(){}};}};

var cc =
      {
        version: '0.15',
        ccversion: '1.0.10',
        jqueryversionrequired: '1.4.4',
        initobj: false,
        setupcomplete: false,
        allasked: false,
        checkedlocal: false,
        jqueryattempts: 0,
        allagree: true,
        cookies: {},
        defaultCookies: { social: {}, analytics: {}, profiling: {}},
        approved: {},
        bindfunctions: {},
        checkeddonottrack: false,
        clickedElem: '',
        readyCallback: function() {},

        settings: {
          scriptwaittime: 1000,
          scrolllistenerwaittime: 1000,
          cookieprefix: "FRG_",
          clickAnyLinkToConsent: false,
          dragScrollbarToConsent: false,
          messagetype: "customdialog",
          consenttype: 'explicit',
          onlyshowbanneronce: true,
          testmode: false,
          ignoreDoNotTrack: true,
          disableClickTrigger: false,
          domainlevel: true,
          debugLog: false,
          locale: '',
          privacypage: ''
        },

        ready: function(callback) {
          if (callback && typeof callback === "function") {
            cc.log("set a ready callback: "+callback);
            cc.readyCallback = callback;
          }
        },

        strings: {},

        log: function(text) {
          if (cc.settings.debugLog && console !== undefined) {
            console.log(text);
          }
        },

        bindClickEvents: function() {
          cc.log("listening for click events");
          jQuery(document).on("click tap", cc._internalClickCallback);
        },

        unbindClickEvents: function() {
          jQuery(document).unbind("click tap", cc._internalClickCallback);
        },

        disableInlineJs: function() {
          if (cc.settings.disableClickTrigger) {
            return;
          }
          jQuery('[onclick]').each(function() {
            var $t = jQuery(this);
            $t.attr('_onclick', $t.attr('onclick'));
            $t.removeAttr('onclick');
          });
          jQuery("a:not(.cc-link)[href]").each(function() {
            var $t = jQuery(this);
            $t.attr('_href', $t.attr('href'));
            $t.attr('href', '');
          });
        },

        enableInlineJs: function() {
          if (cc.settings.disableClickTrigger) {
            return;
          }
          jQuery('[_onclick]').each(function() {
            var $t = jQuery(this);
            $t.attr('onclick', $t.attr('_onclick'));
            $t.removeAttr('_onclick');
          });
          jQuery('a:not(.cc-link)[_href]').each(function() {
            var $t = jQuery(this);
            $t.attr('href', $t.attr('_href'));
            $t.removeAttr('_href');
          });
        },

        onconsent: function (cookieType, input) {
          if (cc.isfunction(input)) {
            fn = input;
          }
          else {
            scriptname = input;
            fn = function () {
              cc.insertscript(scriptname);
            };
          }
          if (cc.cookies && cc.cookies[cookieType] && cc.cookies[cookieType].approved) {
            cc.cookies[cookieType].executed = true;
            fn();
          } else {
            if (window.jQuery) {
              jQuery(document).bind(cc.settings.cookieprefix + cookieType, fn);
            } else {
              if (cc.bindfunctions[cookieType]) {
                cc.bindfunctions[cookieType][cc.bindfunctions[cookieType].length] = fn;
              } else {
                cc.bindfunctions[cookieType] = new Array(fn);
              }
            }
          }
        },

        geturlparameter: function (name) {
          name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
          var regexS = "[\\?&]" + name + "=([^&#]*)";
          var regex = new RegExp(regexS);
          var results = regex.exec(window.location.search);
          if (results == null)
            return false;
          else
            return decodeURIComponent(results[1].replace(/\+/g, " "));
        },

        isfunction: function (functionToCheck) {
          var getType = {};
          return functionToCheck && getType.toString.call(functionToCheck) == '[object Function]';
        },

        setup: function () {
          jQuery.each(cc.bindfunctions, function (key, value) {
            for (i = 0; i < value.length; i++) {
              jQuery(document).bind(cc.settings.cookieprefix + key, value[i]);
            }
          });
          verstr = jQuery().jquery;
          parts = verstr.split('.');
          versionRequired = cc.jqueryversionrequired.split('.');
          jqueryOk = true;
          for (i = 0; i < parts.length && i < versionRequired.length; i++) {
            currentpart = parseInt(parts[i]);
            requiredpart = parseInt(versionRequired[i]);
            if (currentpart < requiredpart) {
              /* Unsatisfied - this part of the version string is less than the version we require */
              jqueryok = false;
              break;
            }
            if (currentpart > requiredpart) {
              /* Satisfied - this part of the version string is greater than the version we require */
              break;
            }
            /* This version is the same as the one we require.  Check the next part of the version number. */
          }
          if (!jqueryOk) {
            alert(cc.strings.jqueryWarning);
          }

          jQuery.each(cc.initobj.cookies, function (key, value) {

            if (!value.title) {
              if (key == "necessary") {
                cc.initobj.cookies[key].title = cc.strings.necessaryDefaultTitle;
              }
              else if (key == "social") {
                cc.initobj.cookies[key].title = cc.strings.socialDefaultTitle;
              }
              else if (key == "analytics") {
                cc.initobj.cookies[key].title = cc.strings.analyticsDefaultTitle;
              }
              else if (key == "profiling") {
                cc.initobj.cookies[key].title = cc.strings.profilingDefaultTitle;
              } else {
                cc.initobj.cookies[key].title = cc.strings.defaultTitle;
              }
            }
            if (!value.description) {
              if (key == "necessary") {
                cc.initobj.cookies[key].description = cc.strings.necessaryDefaultDescription;
              }
              else if (key == "social") {
                cc.initobj.cookies[key].description = cc.strings.socialDefaultDescription;
              }
              else if (key == "analytics") {
                cc.initobj.cookies[key].description = cc.strings.analyticsDefaultDescription;
              }
              else if (key == "profiling") {
                cc.initobj.cookies[key].description = cc.strings.profilingDefaultDescription;
              } else {
                cc.initobj.cookies[key].description = cc.strings.defaultDescription;
              }
            }

            if (!value.defaultstate) {
              cc.initobj.cookies[key].defaultstate = "on";
            }

            cc.initobj.cookies[key].asked = false;
            cc.initobj.cookies[key].approved = false;
            cc.initobj.cookies[key].executed = false;
          });

          testmode = cc.geturlparameter('cctestmode');
          if (testmode == 'accept' || testmode == 'decline') {
            cc.settings.testmode = testmode;
          }

          for (var attrname in cc.initobj.cookies) {
            cc.cookies[attrname] = cc.initobj.cookies[attrname];
            if (cc.settings.testmode == "accept") {
              cc.approved[attrname] = "yes";
            }
            if (cc.settings.testmode == "decline") {
              cc.approved[attrname] = "no";
            }
          }

        },

        initialise: function (obj) {
          cc.initobj = obj;
          if (obj.settings !== undefined) {
            for (var attrname in obj.settings) {
              cc.settings[attrname] = obj.settings[attrname];
            }
          }
          var stringsLang;
          // loads dafaults from i18n file
          this.loadStrings(cc_i18n.strings);

          if (this.settings.locale) {
            var lang = this.settings.locale.split('_')[0];
            stringsLang = cc_i18n['strings_'+lang];
            // loads lang defaults from i18n file (overrides prev)
            this.loadStrings(stringsLang);

            stringsLang = cc_i18n['strings_'+this.settings.locale];
            // loads full locale from i18n file (overrides prev)
            this.loadStrings(stringsLang);
          }

          // loads entries from initialise call (overrides prev)
          this.loadStrings(obj.strings);

          if (cc.initobj.cookies === undefined) {
            cc.initobj.cookies = cc.defaultCookies;
          }

          if (window.jQuery) {
            cc.setupcomplete = true;
            cc.setup();
          }
        },

        loadStrings: function(stringsI18n) {
          if (stringsI18n !== undefined) {
            for (var attrname in stringsI18n) {
              cc.strings[attrname] = stringsI18n[attrname];
            }
          }
        },

        insertscript: function (script) {
          var newfile = document.createElement('script');
          newfile.setAttribute("type", "text/javascript");
          newfile.setAttribute("src", script);
          document.getElementsByTagName("head")[0].appendChild(newfile);
        },

        insertscripttag: function (content) {
          var newfile = document.createElement('script');
          newfile.setAttribute("type", "text/javascript");
          newfile.innerHTML = content;
          document.getElementsByTagName("head")[0].appendChild(newfile);
        },

        checklocal: function () {
          this.checkedlocal = true;
          jQuery.each(cc.cookies, function (key, value) {
            cookieval = cc.getcookie(cc.settings.cookieprefix + key);
            if (cookieval) {
              cc.approved[key] = cookieval;
            }
          });
          this.checkapproval();
        },

        setcookies: function (data) {
          jQuery.each(data, function (key, value) {
            if (cc.cookies[key] && !cc.approved[key]) {
              cc.setcookie(cc.settings.cookieprefix + key, value, 365);
            }
          });

          for (var attrname in data) {
            if (this.approved[attrname] != "yes" && this.approved[attrname] != "no") {
              this.approved[attrname] = data[attrname];
            }
          }

          this.checkapproval();
        },

        deletecookie: function (key) {
          date = new Date();
          date.setDate(date.getDate() - 1);
          document.cookie = escape(cc.settings.cookieprefix + key) + '=; path=/; expires=' + date;
        },

        _internalClickCallback: function(event) {
          var target = event.target;
          var skipTrigger = false;
          if (!jQuery(target).hasClass("cc-link")) {

            cc.log("_internalClickCallback target:"+target);
            var $target = jQuery(target);
            var events = jQuery._data($target[0], "events" );
            if (cc.settings.disableClickTrigger || (events !== undefined && events.click !== undefined && events.click[0].namespace == "cc") ) {
              cc.log("_internalClickCallback click is empty");
              cc.onconsentgiven();
              skipTrigger = true;
            }

            if (!skipTrigger && cc.clickedElem == '') {
              cc.log("preventDefault");
              cc.clickedElem = event.target;
              event.preventDefault();
              cc.onconsentgiven();

              setTimeout(function(){
                cc.log("triggering click on: "+cc.clickedElem);
                cc.clickedElem.click();
                cc.clickedElem = '';
              }, cc.settings.scriptwaittime);
            }
          }
        },

        _internalDragCallback: function() {
          cc.log("triggering scroll. pageYOffset="+window.pageYOffset);
          // check for viewport change to ignore async page update events
          if (window.pageYOffset != 0) {
            cc.onconsentgiven();
          }
        },

        showcustomdialog: function () {
          cc.log("showcustomdialog");

          for (var attrname in this.strings) {
            if (attrname.indexOf("cc_cd_lbl")==0) {
              jQuery('.'+attrname).append(this.strings[attrname]);
            }
          }

          if (cc.settings.privacypage) {
            jQuery('a.cc-privacy-link').attr('href', cc.settings.privacypage);
          }

          jQuery(".cc-customdialog").css("z-index", 2147483647);
          jQuery('.cc-customdialog').show();
          jQuery('#cc-approve-button-thissite').click(cc.onconsentgiven);

          if (cc.settings.dragScrollbarToConsent) {
            // adding a small timeout to avoid event triggering on page load
            setTimeout(function(){
              cc.log("listening for scroll events");
              //Change for FERRAGAMO-2137
              if (cc.settings.clickAnyLinkToConsent) {
                cc.bindClickEvents();
                cc.disableInlineJs();
              }
              jQuery(document).scroll(cc._internalDragCallback);
            }, cc.settings.scrolllistenerwaittime);
          }
        },

        showdialog: function () {
          cc.log("showdialog");
        },

        timestamp: function () {
          return Math.round((new Date()).getTime() / 1000);
        },

        checkdonottrack: function () {
          cc.checkeddonottrack = true;
          if (!cc.settings.ignoreDoNotTrack) {
            if (navigator.doNotTrack == "yes" || navigator.doNotTrack == "1" || navigator.msDoNotTrack == "yes" || navigator.msDoNotTrack == "1") {
              cc.settings.consenttype = "explicit";
            }
          }
          cc.checkapproval();
        },

        checkapproval: function () {
          cc.log("checkapproval");

          cc.allasked = true;
          cc.checkAllChecked = false;
          jQuery.each(cc.cookies, function (key, value) {
            if (cc.approved[key]) {
              if (cc.approved[key] == "yes") {
                cc.cookies[key].asked = true;
                cc.cookies[key].approved = true;
                cc.execute(key);
                counterAsked+=1;
              } else if (cc.approved[key] == "no") {
                cc.cookies[key].asked = true;
                cc.cookies[key].approved = false;
                counterAsked+=1;
              } else {
                cc.allasked = false;
              }
            } else {
              cc.allasked = false;
            }
          });

          if (!cc.allasked) {
            if (!cc.checkedlocal) {
              cc.checklocal();
              return;
            }
            if (!cc.checkeddonottrack) {
              cc.checkdonottrack();
              return;
            }
            if (cc.settings.consenttype == "implicit") {
              jQuery.each(cc.cookies, function (key, value) {
                if (!cc.cookies[key].asked) {
                  if (cc.settings.onlyshowbanneronce) {
                    cc.setcookie(cc.settings.cookieprefix + key, 'yes', 365);
                  }
                  cc.execute(key);
                }
              });
            }
            if (cc.settings.messagetype == "dialog") {
              cc.showdialog();
            } if (cc.settings.messagetype == "customdialog" && jQuery('.cc-customdialog').is(":hidden")) {
              cc.showcustomdialog();
            }
          }else{
            var counterAsked = 0;
            jQuery.each(cc.cookies, function (key, value) {
              if(jQuery("input:radio[name=cc-radio-"+key+"]").is(':checked')){
                counterAsked+=1;
              }
            });
            if(counterAsked >= 3){
              cc.checkAllChecked = true;
              jQuery('.cc-customdialog').hide();
            }
          }
        },

        execute: function (cookieType) {
          cc.log("execute");
          if (cookieType == "necessary") {
            return;
          }
          if (cc.cookies[cookieType].executed) {
            return;
          }

          jQuery('.cc-placeholder-' + cookieType).remove();
          jQuery('script.cc-onconsent-' + cookieType + '[type="text/disabled"]').each(function () {
            if (jQuery(this).attr('src')) {
              jQuery(this).after('<script type="text/javascript" src="' + jQuery(this).attr('src') + '"></script>');
            } else {
              jQuery(this).after('<script type="text/javascript">' + jQuery(this).html() + '</script>');
            }
          });
          cc.cookies[cookieType].executed = true;
          jQuery(document).trigger(cc.settings.cookieprefix + cookieType);
        },

        getcookie: function (c_name) {
          var i, x, y, ARRcookies = document.cookie.split(";");
          for (i = 0; i < ARRcookies.length; i++) {
            x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
            y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
            x = x.replace(/^\s+|\s+$/g, "");
            if (x == c_name) {
              return unescape(y);
            }
          }
          return false;
        },

        setcookie: function (name, value, expirydays) {
          cc.log("setcookie "+name+"="+value);
          var exdate = new Date();
          exdate.setDate(exdate.getDate() + expirydays);
          var cookieTxt = name + '=' + value + '; expires=' + exdate.toUTCString() + '; path=/'

          // added 2nd level domain cookies opt
          var domain = location.hostname;
          if (this.settings.domainlevel && domain != 'localhost') {
            domain = domain.split('.').slice(-2).join('.');
            cookieTxt += ";domain=."+domain;
          }

          document.cookie = cookieTxt;
        },

        onconsentgiven: function () {
          cc.log("onconsentgiven");
          enableall = false;
          enablejustone = false;
          cc.isRefusedAll = false;
          if (jQuery(this).hasClass('cc-button-enableall') || jQuery(this).hasClass('cc-button-enable-all')) {
            enableall = true;
            jQuery.each(cc.cookies, function (key, value) {
              cc.cookies[key].asked = false;
              if(dataLayer){
                dataLayer.push({
                  'event': 'change_FRG_' + key
                });
              }
            });
          }
          if (jQuery(this).hasClass('cc-button-refuseall') || jQuery(this).hasClass('cc-button-refuse-all')) {
            enableall = false;
            cc.isRefusedAll = true;
            jQuery.each(cc.cookies, function (key, value) {
              cc.cookies[key].asked = false;
            });
          }
          elem = this;
          jQuery.each(cc.cookies, function (key, value) {
            if (jQuery(elem).hasClass('cc-button-enable-' + key)) {
              enablejustone = true;
              cc.approved[key] = "yes";
              cc.cookies[key].asked = true;
              cc.setcookie(cc.settings.cookieprefix + key, cc.approved[key], 365);
            }
          });

          cc.allagree = true;
          if (!enablejustone) {
            if (cc.settings.clickAnyLinkToConsent || cc.settings.dragScrollbarToConsent) {
              enableall = true;
              jQuery.each(cc.cookies, function (key, value) {
                cc.cookies[key].asked = false;
              });
              cc.unbindClickEvents();
              jQuery(document).unbind("scroll", cc._internalDragCallback);
              cc.enableInlineJs();
            }
            jQuery.each(cc.cookies, function (key, value) {
              if (!value.approved && !value.asked) {
                if (enableall) {
                  cc.approved[key] = "yes";
                  cc.cookies[key].asked = true;
                  cc.setcookie(cc.settings.cookieprefix + key, cc.approved[key], 365);
                } else {
                  cc.approved[key] = "no";
                  cc.cookies[key].asked = true;
                  cc.allagree = false;
                  if(key === 'analytics'){
                    if(cc.approved[key] && cc.approved[key] !== 'no'){
                      cc.approved[key] = "yes";
                      cc.cookies[key].asked = true;
                      cc.setcookie(cc.settings.cookieprefix + key, cc.approved[key], 365);
                    }
                  }
                }
              } else {
              }
            });
          }

          if (cc.settings.messagetype=="customdialog" && !cc.isRefusedAll) {
            jQuery('.cc-customdialog').hide();
          }

          cc.checkapproval();
          if(cc.isRefusedAll){
            location.href = jQuery(this).attr('href');
          }

          if($('.legalpage-content__button__radio').length > 0 && enableall === true){
            cc.updateradios();
          }

          if (jQuery(this).hasClass('cc-button-enableall') || jQuery(this).hasClass('cc-button-enable-all')) {
            jQuery.each(cc.cookies, function (key, value) {
              if(dataLayer){
                dataLayer.push({
                  'event': 'change_FRG_' + key
                });
              }
            });
          }
          return false;
        },

        iscookieenabled: function(key) {
          cc.log("iscookieenabled "+key);
          //jQuery('#cc-checkbox-' + key).is(':checked');
          return jQuery("input:radio[name=cc-radio-"+key+"][value=1]").prop("checked");
        },

        updateradios: function() {
          cc.log("updateradios");
          if (cc.getsize(cc.cookies) > 0) {
            jQuery.each(cc.cookies, function (key, value) {
              cc.log("updateradios key="+key+" val="+cc.approved[key]);
              var radioVal = -1;
              if(cc.approved[key] === 'yes' || $.cookie('FRG_' + key) === 'yes'){
                radioVal = 1;
                jQuery("input:radio[name=cc-radio-"+key+"][value="+radioVal+"]").prop("checked",true);
              }else if(cc.approved[key] === 'no' || $.cookie('FRG_' + key) === 'no'){
                radioVal = 0;
                jQuery("input:radio[name=cc-radio-"+key+"][value="+radioVal+"]").prop("checked",true);
              }

            });
          }
          jQuery("input:radio[name|=cc-radio]").change(function(){
            cc.log("RADIO "+this.value);
            if (jQuery(this).is(':checked')) {
              var key = this.name.substring(9);
              cc.log("RADIO CHECKED! "+key+"="+this.value);
              cc.approved[key] = (this.value=="1")?"yes":"no";
              cc.setcookie(cc.settings.cookieprefix + key, cc.approved[key], 365);
              if(dataLayer){
                dataLayer.push({
                  'event': 'change_FRG_' + key,
                  'newConsent': cc.approved[key]
                });
              }
              cc.checkapproval();
            }
          });
        },

        getsize: function (obj) {
          var size = 0, key;
          for (key in obj) {
            if (obj.hasOwnProperty(key)) size++;
          }
          return size;
        },

        clearalllocalcookies: function () {
          var cookies = document.cookie.split(";");

          for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
          }
        },

        clearlocal: function () {
          cc.clearalllocalcookies();
          jQuery(this).before('<p>' + cc.strings.clearedCookies + '</p>')
        },

        getcurrenturl: function () {
          return window.location.protocol + "//" + window.location.host + window.location.pathname;
        },


        onfirstload: function () {
          cc.log("onfirstload");
          if (!cc.setupcomplete && cc.initobj) {
            if (!(window.jQuery)) {
              cc.jqueryattempts++;
              if (cc.jqueryattempts >= 5) {
                return;
              }
              setTimeout(cc.onfirstload, 200);
              return;
            }
            cc.setupcomplete = true;
            cc.setup();
          }
          cc.afterload();
          cc.checkapproval();
        },

        isapproved: function(name) {
          return this.approved[name] == "yes" ? true : false;
        },

        isasked: function(name) {
          return this.cookies[name].asked;
        },

        afterload: function () {
          cc.log("afterload");
          jQuery('.cc-button-enableall').addClass('cc-link').click(cc.onconsentgiven);
          jQuery('.cc-button-enable-all').addClass('cc-link').click(cc.onconsentgiven);
          jQuery('.cc-button-refuseall').addClass('cc-link').click(cc.onconsentgiven);
          jQuery('.cc-button-refuse-all').addClass('cc-link').click(cc.onconsentgiven);

          jQuery.each(cc.cookies, function (key, value) {
            jQuery('.cc-button-enable-' + key).addClass('cc-link').click(cc.onconsentgiven);
          });

          if (cc.settings.consenttype == 'hybrid-allenabled') {
            var activationCookies = {};
            jQuery.each(cc.cookies, function (key, value) {
              if (!cc.cookies[key].asked) {
                activationCookies[key] = "yes";
              }
            });
            cc.setcookies(activationCookies);
            cc.updateradios();
          }
          if(cc.settings.consenttype == 'explicit'){
            jQuery.each(cc.cookies, function (key, value) {
              if(key === 'analytics'){
                if(!$.cookie('FRG_analytics')){
                  cc.approved[key] = "yes";
                  cc.cookies[key].asked = true;
                  cc.setcookie(cc.settings.cookieprefix + key, cc.approved[key], 365);
                } else if($.cookie('FRG_analytics') === 'no'){
                  cc.approved[key] = "no";
                  cc.cookies[key].asked = true;
                  cc.setcookie(cc.settings.cookieprefix + key, cc.approved[key], 365);
                }
              }
            });
            cc.updateradios();
          }
          cc.readyCallback();
        }
      }

cc.initialise(ccSettings);
cc.onfirstload();

//if (!(window.jQuery)) {
//	cc.log("jquery not found");
//    var s = document.createElement('script');
//    s.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jquery/1.7.2/jquery.min.js');
//    s.setAttribute('type', 'text/javascript');
//    document.getElementsByTagName('head')[0].appendChild(s);
//    if (window.onload != null) {
//        var oldOnload = window.onload;
//        window.onload = function (e) {
//            oldOnload(e);
//            cc.onfirstload();
//        };
//    }
//    else {
//        window.onload = cc.onfirstload;
//    }
//} else {
//	cc.log("jquery found");
//    jQuery(document).ready(cc.onfirstload);
//}
