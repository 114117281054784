import { gsap } from 'gsap';

export default function tutorialLeave(level = 'one', stageStaticLines, tutorial) {
  // Tutorial fade in animation
  return gsap.timeline()
    .addLabel('tutorial')
    .to(stageStaticLines, {
      backgroundColor: 'rgba(0, 0, 0, .1)',
      duration: 1,
      ease: 'power2.inOut',
    }, 'tutorial')
    .to(tutorial, {
      autoAlpha: 1,
      duration: 1,
      ease: 'power2.inOut',
    }, 'tutorial')
    .to(tutorial, {
      autoAlpha: 0,
      duration: 1,
      ease: 'power2.inOut',
      delay: level === 'one' ? 2.5 : 5,
    });
}
