import { gsap } from 'gsap';
import { init, enter, leave } from '../animations';

export function initBlockText(el) {
  const title = el.querySelector('.title-text__title');
  const text = el.querySelector('.title-text__text');
  const cta = el.querySelector('.b-cta');

  init.get('title')(title);
  init.get('fade')([text, cta]);
}

export function enterBlockText(el) {
  const title = el.querySelector('.title-text__title');
  const text = el.querySelector('.title-text__text');
  const cta = el.querySelector('.b-cta');
  const tl = gsap.timeline();

  init.get('title')(title);
  init.get('fade')([text, cta]);

  tl.addLabel('start')
    .add(enter.get('title')(title), 'start')
    .add(enter.get('fade')(text), 'start+=0.5')
    .add(enter.get('fade')(cta), 'start+=0.8');

  return tl;
}

export function leaveBlockText(el) {
  const title = el.querySelector('.title-text__title');
  const text = el.querySelector('.title-text__text');
  const cta = el.querySelector('.b-cta');
  const tl = gsap.timeline();

  tl.addLabel('start')
    .add(leave.get('title')(title), 'start')
    .add(leave.get('fade')(text), 'start+=0.45')
    .add(leave.get('fade')(cta), 'start+=0.6');

  return tl;
}
