export default {
  meta: {
    title: '메트로폴리탄 수수께끼 - Salvatore Ferragamo',
    description: '살바토레 페라가모 수수께끼를 풀고 특별한 서프라이즈를 경험하세요.',
  },

  header: {
    back: '홈으로 돌아가기',
    collection: 'SS21 보기',
  },

  nextGameCta: '다음 게임하기',
  endGameCta: '마지막 수수께끼 풀기',

  CookieBar: {
    text: 'Ferragamo.com은 고객에게 광고를 전달하기 위한 목적 이외에도 통계적 목적과 개인화된 경험을 제공하기 위하여 사용자의 동의 하에 웹사이트의 적절한 기능을 담보할 목적으로 자사 및 타사의 쿠키를 사용합니다. 이에 관한 좀 더 자세한 정보 및 기본 설정 변경을 원하시는 분들은 “옵션관리” 버튼을 클릭하십시오.',
    manage_options: '옵션관리',
    accept: '동의함',
    code: 'ko_31650',
    link: 'https://www.ferragamo.com/shop/kor/ko/sf/legal/cookie-policy#cookieSettings',
  },

  loader: {
    titleText: {
      title: '메트로폴리탄 수수께끼',
      text: '살바토레 페라가모 수수께끼를 풀고 특별한 서프라이즈를 경험해보세요.<br>이탈리아 유명 감독 루카 과다니노와 크리에이티브 디렉터 폴 앤드류가 <br>공동 제작한 SS21 패션 필름의 배경인 밀라노 거리에서 게임을 즐겨보세요.',
    },
    cta: '게임 시작',
  },

  idroscalo: {
    titleText: {
      title: 'Parco <br class="only-mobile"> dell’Idroscalo',
      text: '미스터리를 풀기 위해서는 무엇을 찾고 있는지 아는 것이 중요합니다.<br>힌트를 모아 수수께끼를 맞춰보세요!',
    },
    cta: '게임 시작',
    nextLink: 'idroscalo/memory',
  },

  memory: {
    inGame: '기억력과 온전한 직감은 탐정의 가장 뛰어난 도구입니다!',
    titleText: {
      title: '정확하게 기억을 잘하셨어요!',
      text: '수수께끼를 해결하는데 한 걸음 더 가까워졌습니다.',
    },
    nextLink: 'via-chiossetto',
  },

  'via-chiossetto': {
    titleText: {
      title: 'Via <br class="only-mobile"> Chiossetto',
      text: '용의자를 심문하는 것은 예상보다 훨씬 더 까다로운 작업입니다.<br>당신이 잘 해낼 수 있는지 알아보는 시간입니다.',
    },
    cta: '게임 시작',
    nextLink: 'via-chiossetto/crosswords',
  },

  crosswords: {
    inGame: '올바른 답을 찾기 위해서는 올바른 질문을 하는 것이 중요합니다!',
    letters: [
      ['T', 'B', 'S', 'Y', 'B', 'E', 'O', 'E', 'D', 'W', 'U'],
      ['E', 'N', 'V', 'E', 'N', 'I', 'G', 'M', 'A', 'N', 'J'],
      ['C', 'C', 'G', 'B', 'C', 'C', 'V', 'N', 'I', 'K', 'B'],
      ['H', 'Z', 'I', 'W', 'Y', 'C', 'B', 'Z', 'E', 'Q', 'C'],
      ['N', 'T', 'V', 'A', 'S', 'M', 'V', 'B', 'J', 'X', 'E'],
      ['I', 'E', 'J', 'A', 'Z', 'G', 'X', 'L', 'I', 'F', 'E'],
      ['C', 'R', 'U', 'S', 'R', 'P', 'V', 'M', 'W', 'P', 'F'],
      ['O', 'B', 'X', 'Y', 'F', 'F', 'G', 'G', 'V', 'P', 'I'],
      ['L', 'P', 'Z', 'B', 'E', 'A', 'U', 'T', 'Y', 'O', 'C'],
      ['O', 'J', 'B', 'Q', 'C', 'F', 'P', 'H', 'K', 'U', 'H'],
      ['R', 'F', 'K', 'Y', 'E', 'W', 'G', 'A', 'H', 'V', 'S'],
    ],
    words: ['Life', 'Enigma', 'Beauty', 'Technicolor'],
    titleText: {
      title: '스마트한 탐정이시군요!',
      text: '수수께끼를 해결하는데 한 걸음 더 가까워졌습니다.',
    },
    nextLink: 'galleria-strasburgo',
  },

  'galleria-strasburgo': {
    titleText: {
      title: 'Galleria <br class="only-mobile"> Strasburgo',
      text: '스마트한 탐정은 많은 단서를 서로 연결할 수 있어야 합니다.',
    },
    cta: '게임 시작',
    nextLink: 'galleria-strasburgo/connects',
  },

  connects: {
    tutorial: '간치니를 드래그하고 무대를 회전시켜  두 부분을 연결합니다. ',
    inGame: '당신에게 유리하도록 지도를 펴고 용의자를 가두세요!',
    titleText: {
      title: '당신은 스마트한 탐정이군요.',
      text: '수수께끼를 해결하는데 한 걸음 더 가까워졌습니다.',
    },
    nextLink: 'idroscalo',
  },

  'end-game': {
    titleText: {
      title: '여기 마지막으로 해결해야할 수수께끼가 있습니다.',
      text: '성공으로 가는 마지막 라운드!<br>링크를 따라 마지막 수수께끼를 풀고 깜짝 선물을 받아보세요! ',
    },
    nextLink: 'https://www.ferragamo.com/enigma',
  },

  404: {
    message: '문제가 발생했습니다.',
    cta: '홈으로 돌아가기',
  },

  rotate: {
    message: '디바이스를 회전해 주십시오.',
    thanks: '감사합니다.',
  },
};
