export default {
  meta: {
    title: 'L\'Enigme métropolitaine - Salvatore Ferragamo',
    description: 'Résolvez l\'Enigme de Salvatore Ferragamo et découvrez des surprises exceptionnelles.',
  },

  header: {
    back: 'Retour à la page d\'accueil',
    collection: 'Voir PE21',
  },

  nextGameCta: 'Jouer au jeu suivant',
  endGameCta: 'Résolvez la dernière énigme',

  CookieBar: {
    text: 'Ferragamo.com utilise ses propres cookies et ceux de tiers pour assurer le bon fonctionnement du site Internet et - avec votre consentement - à des fins statistiques et pour vous offrir une expérience personnalisée, ainsi que pour vous envoyer des communications publicitaires. Pour plus d\'informations et pour modifier vos préférences, cliquez sur le bouton « Gérer les options ».',
    manage_options: 'GÉRER LES OPTIONS',
    accept: 'J\'ACCEPTE',
    code: 'fr_31152',
    link: 'https://www.ferragamo.com/shop/fra/fr/sf/legal/confidentialit%C3%A9-et-cookies#cookieSettings',
  },

  loader: {
    titleText: {
      title: 'L\'Enigme métropolitaine',
      text: 'Résolvez l\'Enigme de Salvatore Ferragamo et découvrez des surprises exceptionnelles.<br>Jouez avec nous dans les rues de Milan, lieu du fashion film PE21 exclusif, tourné par le célèbre réalisateur italien Luca Guadagnino en collaboration avec notre directeur de la création Paul Andrew.',
    },
    cta: 'Commencez l\'enquête',
  },

  idroscalo: {
    titleText: {
      title: 'Parco <br class="only-mobile"> dell’Idroscalo',
      text: 'Pour résoudre n\'importe quel mystère, il est fondamental de savoir ce que vous cherchez.<br>L\'astuce est de rassembler des indices et de s\'informer!',
    },
    cta: 'Commencez à résoudre',
    nextLink: 'idroscalo/memory',
  },

  memory: {
    inGame: 'La mémoire et l\'intuition pure sont les outils les plus fiables d\'un détective!',
    titleText: {
      title: 'Excellente mémoire !',
      text: 'Vous avez fait un pas de plus vers la résolution de l\'Enigme.',
    },
    nextLink: 'via-chiossetto',
  },

  'via-chiossetto': {
    titleText: {
      title: 'Via <br class="only-mobile"> Chiossetto',
      text: 'Interroger des suspects est bien plus délicat que ce à quoi on pourrait s\'attendre.<br>Il est temps de voir si vous pouvez lire dans leurs pensées!',
    },
    cta: 'Cherchez les réponses',
    nextLink: 'via-chiossetto/crosswords',
  },

  crosswords: {
    inGame: 'Il est important de poser les bonnes questions, mais plus encore de chercher les bonnes réponses!',
    letters: [
      ['T', 'B', 'S', 'Y', 'B', 'E', 'O', 'E', 'D', 'W', 'U'],
      ['E', 'N', 'V', 'E', 'N', 'I', 'G', 'M', 'A', 'N', 'J'],
      ['C', 'C', 'G', 'B', 'C', 'C', 'V', 'N', 'I', 'K', 'B'],
      ['H', 'Z', 'I', 'W', 'Y', 'C', 'B', 'Z', 'E', 'Q', 'C'],
      ['N', 'T', 'V', 'A', 'S', 'M', 'V', 'B', 'J', 'X', 'E'],
      ['I', 'E', 'J', 'A', 'Z', 'G', 'X', 'L', 'I', 'F', 'E'],
      ['C', 'R', 'U', 'S', 'R', 'P', 'V', 'M', 'W', 'P', 'F'],
      ['O', 'B', 'X', 'Y', 'F', 'F', 'G', 'G', 'V', 'P', 'I'],
      ['L', 'P', 'Z', 'B', 'E', 'A', 'U', 'T', 'Y', 'O', 'C'],
      ['O', 'J', 'B', 'Q', 'C', 'F', 'P', 'H', 'K', 'U', 'H'],
      ['R', 'F', 'K', 'Y', 'E', 'W', 'G', 'A', 'H', 'V', 'S'],
    ],
    words: ['Life', 'Enigma', 'Beauty', 'Technicolor'],
    titleText: {
      title: 'Vous êtes un détective futé',
      text: 'Vous avez fait un pas de plus vers la résolution de l\'Enigme.',
    },
    nextLink: 'galleria-strasburgo',
  },

  'galleria-strasburgo': {
    titleText: {
      title: 'Galleria <br class="only-mobile"> Strasburgo',
      text: 'Un bon inspecteur doit être capable de relier les indices.',
    },
    cta: 'Traquez le suspect',
    nextLink: 'galleria-strasburgo/connects',
  },

  connects: {
    tutorial: 'Faire glisser le Gancini et faire tourner la scène pour relier les deux parties',
    inGame: 'Jouez la carte en votre faveur et capturez le suspect !',
    titleText: {
      title: 'Il est toujours bon de jouer malin!',
      text: 'Vous avez fait un pas de plus vers la résolution de l\'Enigme.',
    },
    nextLink: 'idroscalo',
  },

  'end-game': {
    titleText: {
      title: 'Il reste une dernière énigme à résoudre!',
      text: 'Le succès est à portée de main!<br>Suivez le lien pour résoudre la dernière énigme et préparez-vous pour vos surprises!',
    },
    nextLink: 'https://www.ferragamo.com/enigma',
  },

  404: {
    message: 'Quelque chose ne va pas',
    cta: 'Retour à la page d\'accueil',
  },

  rotate: {
    message: 'Veuillez faire pivoter votre appareil.',
    thanks: 'Grazie',
  },
};
