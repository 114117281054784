<template>
  <button
    class="cards__item card"
    :style="{ backgroundColor: data.bgColor }"
    :aria-label="`Link to ${data.cta} page`"
  >
    <div class="card__container">
      <div class="card__images-container" :style="{ backgroundColor: data.mapColor }">
        <img
          class="card__image card__image--map"
          :data-src="`/assets/img/cards/${data.id}-map.png`"
          :alt="data.cta">
        <img
          class="card__image card__image--selection"
          :data-src="`/assets/img/cards/${data.id}-map-selection.png`"
          :alt="data.cta">
        <img
          class="card__image card__image--selection-border"
          :data-src="`/assets/img/cards/${data.id}-map-selection-border.png`"
          :alt="data.cta">
      </div>

      <span class="card__overlay" :class="{ 'card__overlay--op' : isFirefox }" />

      <div class="card__text">
        <p v-html="data.cta" />
        <transition name="fade-line">
          <img
            v-if="$store.state.gameEnded[data.game]"
            class="cards__text__line"
            :src="`/assets/img/cards/${data.id}-line.svg`"
            alt="Line"
          >
        </transition>
      </div>
    </div>
  </button>
</template>

<script>
export default {
  name: 'CardsItem',

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isFirefox: navigator.userAgent.toLowerCase().indexOf('firefox') > -1,
    };
  },
};
</script>

<style lang="scss" scoped>
.fade-line-enter-active,
.fade-line-leave-active {
  transition: opacity .5s;
}
.fade-line-enter,
.fade-line-leave-to {
  opacity: 0;
}

.card {
  --translateX: 0;
  --translateY: 0;
  --rotateZ: 0;

  --totalX: calc(-50% + var(--translateX));
  --totalY: calc(-50% + var(--translateY));

  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;

  box-shadow: 0 19px 38px rgba(0,0,0,0.20), 0 15px 12px rgba(0,0,0,0.12);
  transform: translate3d(var(--totalX), var(--totalY), 0) rotate(var(--rotateZ));
  cursor: pointer;

  &__container {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
  }

  &__images-container {
    position: relative;
    width: grid-size(16);
    height: grid-size(16);
    margin: grid-size(0.5);

    overflow: hidden;

    img {
      @include full();
    }

    @include mq(ipadP) {
      width: grid-size(10);
      height: grid-size(10);
    }
  }

  &__overlay {
    @include full();

    mix-blend-mode: multiply;

    background-image: url('/assets/img/cards/overlay.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    &--op {
      mix-blend-mode: unset;
      opacity: 0.5;
      filter: brightness(20%);
    }
  }

  &__text {
    position: relative;
    margin: grid-size(0.25) 0 grid-size(0.75);

    font-family: $font-family-heading;
    @include font-size($fluid-ml...);
  }

  &__image--selection {
    transition: transform 0.25s $ease-base;
  }
  &__image--selection-border {
    transition: transform 0.5s $ease-base;
  }

  &:hover {
    .card__image--selection {
      transform: translate(-2px, -2px);
    }
    .card__image--selection-border {
      transform: translate(-11px, -11px);
    }
  }

  .cards__text__line {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 120%;
    height: auto;

    transform: translate(-50%);
  }
}
</style>
