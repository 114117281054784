import gsap from 'gsap';
import { fadeLeave } from './fade';

const mq = window.matchMedia('(max-width: 768px)');
const grid = mq.matches ? [6, 4] : [4, 6];

let cards;
let footer;

export function memoryInit(el) {
  cards = el.querySelectorAll('.game__card');
  footer = el.querySelector('.game__footer');

  gsap.set(cards, {
    scale: gsap.utils.distribute({
      base: 0.4,
      amount: 0.2,
      from: 'center',
      grid,
    }),
    backfaceVisibility: 'hidden',
    opacity: 0,
    x(index) {
      return ((index % grid[1]) - grid[1] / 2) * 15;
    },
    y(index) {
      return Math.trunc((index / grid[0] - grid[0] / 2) * 15);
    },
    z: 0,
  });

  gsap.set(footer, {
    opacity: 0,
  });
}

export function memoryAnim() {
  return gsap.timeline()
    .to(cards, {
      scale: 1,
      opacity: 1,
      x: 0,
      y: 0,
      duration: 1,
      ease: 'power1.inOut',
      stagger: {
        each: 0.1,
        from: 'edges',
        grid,
      },
    })
    .to(footer, {
      opacity: 1,
      duration: 1.5,
      ease: 'power3.inOut',
    }, '-=1');
}

export function memoryOut(el) {
  const text = el.querySelector('.game__footer');

  return gsap.timeline()
    .to(cards, {
      scale: gsap.utils.distribute({
        base: 0.7,
        amount: 0.1,
        from: 'edges',
        grid,
      }),
      opacity: 0,
      x(index) {
        return ((index % grid[1]) - grid[1] / 2) * 5;
      },
      y(index) {
        return Math.trunc((index / grid[0] - grid[0] / 2) * 5);
      },
      duration: 0.7,
      ease: 'power1.inOut',
      stagger: {
        each: 0.05,
        from: 'edges',
        grid,
      },
    })
    .to(footer, {
      opacity: 0,
      duration: 1.5,
      ease: 'power3.inOut',
    }, '-=1')
    .add(fadeLeave(text), 0.5);
}
