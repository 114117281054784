import { Howler, Howl } from 'howler';

const audio = {
  sounds: [
    {
      id: 'background',
      file: '/assets/audio/background.mp3',
      ref: null,
      loop: true,
      volume: 0.2,
    },
    {
      id: 'transition',
      file: '/assets/audio/transi.mp3',
      ref: null,
      loop: false,
      volume: 0.5,
    },
    {
      id: 'swoosh',
      file: '/assets/audio/swoosh.mp3',
      ref: null,
      loop: false,
      volume: 0.6,
    },
    {
      id: 'click',
      file: '/assets/audio/tap.mp3',
      ref: null,
      loop: false,
      volume: 0.1,
    },
    {
      id: 'check',
      file: '/assets/audio/check.mp3',
      ref: null,
      loop: false,
      volume: 0.05,
    },
  ],

  init() {
    audio.sounds.forEach((s) => {
      s.ref = new Howl({ src: [s.file], loop: s.loop, volume: s.volume });
      s.promise = () => new Promise((resolve) => s.ref.once('load', resolve));
    });
  },

  load() {
    return new Promise((resolve) => {
      const promises = [];
      audio.sounds.forEach((s) => promises.push(s.promise()));

      Promise.all(promises).then(resolve);
    });
  },

  get(id) {
    return audio.sounds.find((s) => s.id === id).ref;
  },

  rate(speed, id) {
    audio.get(id).rate(speed);
  },

  play(id) {
    audio.get(id).play();
  },

  pause(id) {
    audio.get(id).pause();
  },

  mute() {
    Howler.mute(true);
  },

  unmute() {
    Howler.mute(false);
  },
};

export default audio;
