export default {
  REMOVE_LOADER(state) {
    state.showLoader = false;
  },
  TOGGLE_CARDS_EVENTS(state) {
    state.cardsEvenetDisable = !state.cardsEvenetDisable;
  },
  TOGGLE_SOUND(state) {
    state.playSound = !state.playSound;
  },
  SET_GAME_COMPLETED(state, payload) {
    const label = `ferragamo_games_${payload}:finished`;
    if (window.localStorage.getItem(label) !== '1') window.localStorage.setItem(label, 1);
    state.gameEnded[payload] = true;
  },
  ADD_KONAMI_LETTER(state, payload) {
    state.konami = state.konami.concat(payload);
  },
  CLEAR_KONAMI_KODE(state) {
    state.konami = '';
  },
  SET_PHASE(state, payload) {
    state.phase = payload;
  },
  SET_LANG(state, payload) {
    state.lang = payload;
  },
};
