export const isTouchDevice = () => {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  const mq = (query) => window.matchMedia(query).matches;
  /* eslint-disable-next-line no-mixed-operators, no-undef */
  if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
    return true;
  }
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
};

export const isSafari = () => typeof navigator !== 'undefined' && navigator.vendor && navigator.vendor.includes('Apple') && navigator.userAgent && !navigator.userAgent.includes('CriOS') && !navigator.userAgent.includes('Chrome') && !navigator.userAgent.includes('FxiOS');

export const isIOS = () => !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

export const sendGAEvent = (action, label) => {
  console.log(`Sending GAEvent: %c${action} %c: %c${label}`, 'color: red', 'color: black', 'color: blue');
  const dataLayer = window.dataLayer || [];
  dataLayer.push({
    event: 'generic_event',
    eventAction: action,
    eventLabel: label,
  });
};

export const getLang = (languages) => {
  const baseLang = navigator.language.split('-')[0];
  const lang = languages.find((l) => l === navigator.language) || languages.find((l) => l === baseLang);

  return lang || 'en';
};

export const loadTexture = (src) => {
  const image = new Image();
  const errorHandler = () => new Error(`Failed to load image's URL: ${src}`);
  const promiseFunction = (resolve) => {
    if (typeof image.decode !== 'undefined') {
      image.decoding = 'sync';
      image.src = src;
      image.decode().then(() => {
        if (typeof window.requestIdleCallback !== 'undefined') {
          requestAnimationFrame(() => requestIdleCallback(() => resolve(image)));
        } else {
          requestAnimationFrame(() => requestAnimationFrame(() => resolve(image)));
        }
      });
    } else {
      const imageHandler = () => {
        image.removeEventListener('load', imageHandler);
        image.removeEventListener('error', errorHandler);

        resolve(image);
      };

      image.addEventListener('load', imageHandler());
      image.addEventListener('error', errorHandler());
    }
  };

  return new Promise(promiseFunction);
};
