import en from '@/assets/i18n/en.js';
import it from '@/assets/i18n/it.js';
import es from '@/assets/i18n/es.js';
import esMX from '@/assets/i18n/es-MX.js';
import fr from '@/assets/i18n/fr.js';
import de from '@/assets/i18n/de.js';
import ko from '@/assets/i18n/ko.js';
import pt from '@/assets/i18n/pt.js';
import ja from '@/assets/i18n/ja.js';
import zh from '@/assets/i18n/zh.js';
import zhHans from '@/assets/i18n/zh-Hans.js';

const t = {
  en,
  it,
  es,
  'es-MX': esMX,
  pt,
  fr,
  de,
  ko,
  ja,
  zh,
  'zh-Hant': zh,
  'zh-TW': zh,
  'zh-CN': zhHans,
  'zh-SG': zhHans,
  'zh-Hans': zhHans,
};

const translate = (key, lang) => t[lang] ? t[lang][key] : 'NO_LANG'; // eslint-disable-line

export default translate;
