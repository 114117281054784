import { gsap } from 'gsap';
import Stage from '@/stage';
import CardsEngine from '../CardsEngine';
import { enterBlockText } from './blockText';

export default function homeToScene(from, to) {
  const tl = gsap.timeline();
  const { scene } = to.dataset;

  tl.addLabel('start')
    .add(() => CardsEngine.toScene(scene), 'start')
    .add(() => Stage.animate(scene, 'enter'), 'start+=1.5')
    .add(enterBlockText(to), 'start+=2');

  return tl;
}
