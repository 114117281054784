export default {
  meta: {
    title: 'The Metropolitan Enigma - Salvatore Ferragamo',
    description: 'Solve the Salvatore Ferragamo Enigma and experience special surprises. ',
  },

  header: {
    back: 'Back home',
    collection: 'View SS21',
  },

  nextGameCta: 'PLAY NEXT GAME',
  endGameCta: 'Solve the last enigma',

  CookieBar: {
    text: 'Ferragamo.com uses its own and third party cookies to ensure the proper functioning of the website and - with your consent - for statistical purposes and to provide you with a personalized experience, as well as to send you advertising communications. For more information and to change your preferences, click on the "Manage Options" button.',
    manage_options: 'Manage options',
    accept: 'I Accept',
    code: 'en_31150',
    link: 'https://www.ferragamo.com/shop/gb/en/sf/legal/cookie-policy#cookieSettings',
  },

  loader: {
    titleText: {
      title: 'The Metropolitan Enigma',
      text: 'Solve the Salvatore Ferragamo Enigma and experience special surprises.<br>Play with us in the streets of Milano, location of the exclusive SS21 fashion film shot by the acclaimed Italian director Luca Guadagnino in collaboration with our Creative Director Paul Andrew.',
    },
    cta: 'Start the Investigation',
  },

  idroscalo: {
    titleText: {
      title: 'Parco <br class="only-mobile"> dell’Idroscalo',
      text: 'To solve any mystery, it is fundamental to know what you’re looking for.<br>It is wise to gather hints and find out!',
    },
    cta: 'Start Solving',
    nextLink: 'idroscalo/memory',
  },

  memory: {
    inGame: 'Memory and sheer intuition are a detective’s most trusted tools!',
    titleText: {
      title: 'Your memory is on point!',
      text: 'You’re one step closer to solving the Enigma.',
    },
    nextLink: 'via-chiossetto',
  },

  'via-chiossetto': {
    titleText: {
      title: 'Via <br class="only-mobile"> Chiossetto',
      text: 'Interrogating suspects is far trickier than you might expect.<br>Time to see if you can read through them!',
    },
    cta: 'Seek the answers',
    nextLink: 'via-chiossetto/crosswords',
  },

  crosswords: {
    inGame: 'It’s important to ask the right questions, but even more so to seek the right answers!',
    letters: [
      ['T', 'B', 'S', 'Y', 'B', 'E', 'O', 'E', 'D', 'W', 'U'],
      ['E', 'N', 'V', 'E', 'N', 'I', 'G', 'M', 'A', 'N', 'J'],
      ['C', 'C', 'G', 'B', 'C', 'C', 'V', 'N', 'I', 'K', 'B'],
      ['H', 'Z', 'I', 'W', 'Y', 'C', 'B', 'Z', 'E', 'Q', 'C'],
      ['N', 'T', 'V', 'A', 'S', 'M', 'V', 'B', 'J', 'X', 'E'],
      ['I', 'E', 'J', 'A', 'Z', 'G', 'X', 'L', 'I', 'F', 'E'],
      ['C', 'R', 'U', 'S', 'R', 'P', 'V', 'M', 'W', 'P', 'F'],
      ['O', 'B', 'X', 'Y', 'F', 'F', 'G', 'G', 'V', 'P', 'I'],
      ['L', 'P', 'Z', 'B', 'E', 'A', 'U', 'T', 'Y', 'O', 'C'],
      ['O', 'J', 'B', 'Q', 'C', 'F', 'P', 'H', 'K', 'U', 'H'],
      ['R', 'F', 'K', 'Y', 'E', 'W', 'G', 'A', 'H', 'V', 'S'],
    ],
    words: ['Life', 'Enigma', 'Beauty', 'Technicolor'],
    titleText: {
      title: 'You are a smart detective',
      text: 'You’re one step closer to solving the enigma.',
    },
    nextLink: 'galleria-strasburgo',
  },

  'galleria-strasburgo': {
    titleText: {
      title: 'Galleria <br class="only-mobile"> Strasburgo',
      text: 'A good detective should be capable of connecting the dots.<br>When investigating, one plus one doesn’t always equal two.',
    },
    cta: 'Chase the suspect',
    nextLink: 'galleria-strasburgo/connects',
  },

  connects: {
    tutorial: 'Drag the Gancini and rotate the stage to connect the two parts',
    inGame: 'Play the map to your favour and lock in the suspect!',
    titleText: {
      title: 'Always good to play smart!',
      text: 'You’re one step closer to solving the Enigma.',
    },
    nextLink: 'idroscalo',
  },

  'end-game': {
    titleText: {
      title: 'There’s one last enigma to solve...',
      text: 'Success is just around the corner!<br>Follow the link to solve the last enigma and claim your surprises!',
    },
    nextLink: 'https://www.ferragamo.com/enigma',
  },

  404: {
    message: 'Something went wrong',
    cta: 'Back to homepage',
  },

  rotate: {
    message: 'Please rotate your device',
    thanks: 'Thank you',
  },
};
