import { gsap } from 'gsap';

const y = { bottom: -35, right: 0, left: 0 };
const x = { bottom: 0, right: 25, left: -25 };
const durations = { long: { enter: 1.6, leave: 1 }, short: { enter: 1, leave: 0.8 } };

function generateAnimation(el, from = 'bottom', dir = 'enter') {
  const duration = durations[from === 'bottom' ? 'long' : 'short'][dir];

  return gsap.to(el, {
    y: dir === 'enter' ? 0 : y[from],
    x: dir === 'enter' ? 0 : x[from],
    autoAlpha: dir === 'enter' ? 1 : 0,
    duration,
    ease: 'power2.inOut',
  });
}

export const fadeInit = (el) => gsap.set(el, { y: 35, autoAlpha: 0 });
export const fadeFromRightInit = (el) => gsap.set(el, { x: 25, autoAlpha: 0 });

export const fadeEnter = (el) => generateAnimation(el);
export const fadeFromRightEnter = (el) => generateAnimation(el, 'right');

export const fadeLeave = (el) => generateAnimation(el, 'bottom', 'leave');
export const fadeFromRightLeave = (el) => generateAnimation(el, 'right', 'leave');
