export default {
  meta: {
    title: 'L’Enigma Metropolitano - Salvatore Ferragamo',
    description: 'Risolvi l’enigma di Salvatore Ferragamo ed ottieni una speciale sorpresa.',
  },
  header: {
    back: 'Torna all\'home page',
    collection: 'View SS21',
  },

  nextGameCta: 'Vai al gioco successivo',
  endGameCta: 'Risolvi l’ultimo enigma',

  CookieBar: {
    text: 'Ferragamo.com utilizza cookie propri e di terze parti, per garantire il corretto funzionamento del sito e – previo tuo consenso – per finalità statistiche e per fornirti un’esperienza e comunicazioni pubblicitarie personalizzate. Per maggiori informazioni e per modificare le tue preferenze clicca sul pulsante “Gestisci Opzioni”',
    manage_options: 'Gestisci opzioni',
    accept: 'Accetto',
    code: 'it_30150',
    link: 'https://www.ferragamo.com/shop/ita/it/sf/note-legali/cookie-policy#cookieSettings',
  },

  loader: {
    titleText: {
      title: 'L’Enigma Metropolitano',
      text: 'Risolvi l’enigma di Salvatore Ferragamo ed ottieni una speciale sorpresa.<br>Gioca con noi tra le strade di Milano, palcoscenico dell’esclusivo fashion film SS21 diretto dal celebre regista Italiano Luca Guadagnino in collaborazione con il nostro direttore creativo Paul Andrew.',
    },
    cta: 'Avvia l’indagine',
  },

  idroscalo: {
    titleText: {
      title: 'Parco <br class="only-mobile"> dell’Idroscalo',
      text: 'Per risolvere qualsiasi mistero, è fondamentale sapere cosa si stia cercando. Raccogli gli indizi e scoprilo!',
    },
    cta: 'Inizia a scoprire',
    nextLink: 'idroscalo/memory',
  },

  memory: {
    inGame: 'Memoria e pura intuizione sono le competenze di un vero detective!',
    titleText: {
      title: 'Ottima memoria!',
      text: 'Sei un passo più vicino a risolvere l’enigma.',
    },
    nextLink: 'via-chiossetto',
  },

  'via-chiossetto': {
    titleText: {
      title: 'Via <br class="only-mobile"> Chiossetto',
      text: 'Interrogare un sospetto è molto più intricato di quanto si possa pensare.<br>È il momento di scoprire se ne sei capace!',
    },
    cta: 'Cerca le risposte',
    nextLink: 'via-chiossetto/crosswords',
  },

  crosswords: {
    inGame: 'È importante porre le domande corrette, ma lo è ancor di più cercare le risposte giuste!',
    letters: [
      ['T', 'B', 'S', 'Y', 'B', 'E', 'O', 'E', 'D', 'W', 'U'],
      ['E', 'N', 'V', 'E', 'N', 'I', 'G', 'M', 'A', 'N', 'J'],
      ['C', 'C', 'G', 'B', 'C', 'C', 'V', 'N', 'I', 'K', 'B'],
      ['H', 'Z', 'I', 'W', 'Y', 'C', 'B', 'Z', 'E', 'Q', 'C'],
      ['N', 'T', 'V', 'A', 'S', 'M', 'V', 'B', 'J', 'X', 'E'],
      ['I', 'E', 'J', 'A', 'Z', 'G', 'X', 'L', 'I', 'F', 'E'],
      ['C', 'R', 'U', 'S', 'R', 'P', 'V', 'M', 'W', 'P', 'F'],
      ['O', 'B', 'X', 'Y', 'F', 'F', 'G', 'G', 'V', 'P', 'I'],
      ['L', 'P', 'Z', 'B', 'E', 'A', 'U', 'T', 'Y', 'O', 'C'],
      ['O', 'J', 'B', 'Q', 'C', 'F', 'P', 'H', 'K', 'U', 'H'],
      ['R', 'F', 'K', 'Y', 'E', 'W', 'G', 'A', 'H', 'V', 'S'],
    ],
    words: ['Life', 'Enigma', 'Beauty', 'Technicolor'],
    titleText: {
      title: 'Sei un sagace detective.',
      text: 'Sei un passo più vicino a risolvere l’enigma.',
    },
    nextLink: 'galleria-strasburgo',
  },

  'galleria-strasburgo': {
    titleText: {
      title: 'Galleria <br class="only-mobile"> Strasburgo',
      text: 'Un buon detective dev’essere in grado di collegare gli indizi.<br>Quando si investiga, uno più uno non sempre fa due.',
    },
    cta: 'Insegui il sospetto',
    nextLink: 'galleria-strasburgo/connects',
  },

  connects: {
    tutorial: 'Trascina i Gancini e ruota il percorso per collegare le due parti',
    inGame: 'Gira la mappa a tuo favore ed agguanta il sospetto!',
    titleText: {
      title: 'Giocare <br class="only-mobile">intelligentemente <br> è sempre la scelta giusta!',
      text: 'Sei un passo più vicino a risolvere l’enigma.',
    },
    nextLink: 'idroscalo',
  },

  'end-game': {
    titleText: {
      title: 'C’è un ultimo enigma da risolvere!',
      text: 'Ci sei quasi, il successo è a portata di mano!<br>Scopri il link, risolvi l’ultimo enigma ed ottieni la tua ricompensa!',
    },
    nextLink: 'https://www.ferragamo.com/enigma',
  },

  404: {
    message: 'Qualcosa è andato storto',
    cta: 'Torna all\' homepage',
  },

  rotate: {
    message: 'Ruota il tuo device',
    thanks: 'Grazie',
  },
};
