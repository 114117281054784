<template>
<section class="b-title-text title-text">
  <h1 class="title-text__title" v-html="content.title" />

  <p
    class="title-text__text"
    :class="{ 'title-text__text--big': isBig }"
    v-html="content.text"
  />
</section>
</template>

<script>
export default {
  name: 'BaseTitleText',

  props: {
    content: {
      type: Object,
      required: false,
      default: () => ({ title: 'Lorem ipsumo dolor sit', text: '' }),
    },
    isBig: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.title-text {
  position: relative;
  text-align: center;

  h1 {
    margin: grid-size(-2) 0 grid-size(3);

    @include font-size($fluid-l...);
  }

  .title-text__text {
    width: grid-size(17);
    margin: 0 auto;

    &--big {
      width: grid-size(20);
    }
  }

  @include mq(ipadP) {
    h1 {
      margin: grid-size(-0.75) 0 grid-size(1);
    }

    .title-text__text {
      width: grid-size(11);

      &--big {
        width: grid-size(12.2);
      }
    }
  }
}
</style>
