import { titleInit, titleEnter, titleLeave } from './title';
import {
  fadeInit,
  fadeEnter,
  fadeLeave,
  fadeFromRightInit,
  fadeFromRightEnter,
  fadeFromRightLeave,
} from './fade';
import { memoryAnim, memoryInit, memoryOut } from './memory';
import { crosswordsAnim, crosswordsInit, crosswordsOut } from './crosswords';
import {
  connectsAnim,
  connectsInit,
  connectsOut,
} from './connects';

const init = new Map();
init.set('title', titleInit);
init.set('fade', fadeInit);
init.set('fadeFromRight', fadeFromRightInit);
init.set('memory', memoryInit);
init.set('crosswords', crosswordsInit);
init.set('connects', connectsInit);

const enter = new Map();
enter.set('title', titleEnter);
enter.set('fade', fadeEnter);
enter.set('fadeFromRight', fadeFromRightEnter);
enter.set('memory', memoryAnim);
enter.set('crosswords', crosswordsAnim);
enter.set('connects', connectsAnim);

const leave = new Map();
leave.set('title', titleLeave);
leave.set('fade', fadeLeave);
leave.set('fadeFromRight', fadeFromRightLeave);
leave.set('memory', memoryOut);
leave.set('crosswords', crosswordsOut);
leave.set('connects', connectsOut);

export { init, enter, leave };
