import {
  Mesh,
  Program,
  Renderer,
  Texture,
  Triangle,
  Vec2,
} from 'ogl';
import { gsap } from 'gsap';
import EventBus from '@/scripts/eventBus';
import screen from '@/scripts/screen';
import { loadTexture } from '@/scripts/utils';

import Scene from './Scene';

import textureMap from './textureMap.json';
import vertex from './shaders/inkmask.vert';
import fragment from './shaders/inkmask.frag';

class Stage {
  constructor() {
    this.renderer = new Renderer({ alpha: true });
    this.renderer.gl.clearColor(1, 1, 1, 0);
    this.scenes = [];
    this.texture = null;
    this.mask = null;
    this.maskTexture = null;
    this.audio = null;

    textureMap.forEach((textureObj) => this.scenes.push(new Scene(textureObj.id, textureObj.texture)));
  }

  async load() {
    const promises = [];

    this.scenes.forEach((scene) => promises.push(scene.load()));

    promises.push(loadTexture('/assets/img/textures/mask.jpg'));

    await Promise.all(promises).then((textures) => {
      this.maskTexture = textures[textures.length - 1];
    });
  }

  appendCanvas(ctx) {
    ctx.appendChild(this.renderer.gl.canvas);

    this.createProgram();
    this.resize();
    this.render();

    EventBus.$on('windowResize', () => this.resize());
  }

  setAudio(audio) {
    this.audio = audio;
  }

  setRatioTextures() {
    this.mesh.program.uniforms.uTextureRatio.value.x = this.currentScene.ratio.x;
    this.mesh.program.uniforms.uTextureRatio.value.y = this.currentScene.ratio.y;
    this.mesh.program.uniforms.uMaskRatio.value.x = Math.min(screen.ratio.x / (640 / 360), 1.0);
    this.mesh.program.uniforms.uMaskRatio.value.y = Math.min(screen.ratio.y / (360 / 640), 1.0);
  }

  resize() {
    this.renderer.setSize(window.innerWidth, window.innerHeight);

    if (this.currentScene) {
      this.currentScene.resize();

      this.setRatioTextures();
    }

    this.render();
  }

  createProgram() {
    this.texture = new Texture(this.renderer.gl, {
      width: 1920,
      height: 1080,
      generateMipmaps: false,
    });
    this.mask = new Texture(this.renderer.gl, {
      width: 2560,
      height: 2160,
      generateMipmaps: false,
    });
    this.mask.image = this.maskTexture;
    this.maskTexture = null;

    const geometry = new Triangle(this.renderer.gl);
    const program = new Program(this.renderer.gl, {
      vertex,
      fragment,
      // transparent: true,
      uniforms: {
        uCurrentX: { value: 0 },
        uCurrentY: { value: 0 },
        uTexture: { value: this.texture },
        uTextureRatio: { value: new Vec2(0, 0) },
        uMask: { value: this.mask },
        uMaskRatio: { value: new Vec2(0, 0) },
        uInvert: { value: true },
      },
    });

    this.mesh = new Mesh(this.renderer.gl, { geometry, program });
  }

  setCurrentTextures() {
    this.texture.needsUpdate = true;

    this.texture.image = this.currentScene.texture;

    this.mesh.program.uniforms.uTexture.value = this.texture;

    this.setRatioTextures();

    this.texture.needsUpdate = false;
  }

  animate(id, dir = 'enter') {
    this.audio.play('transition');
    this.mesh.program.uniforms.uInvert.value = dir === 'leave';
    if (dir === 'enter') {
      this.currentScene = this.scenes.find((scene) => scene.id === id);
      this.setCurrentTextures();
    }

    const lazyObj = { value: 0 };

    gsap.to(lazyObj, {
      value: 23,
      duration: 2,
      ease: 'steps(23)',
      onUpdate: () => {
        const x = lazyObj.value % 4;
        const y = 5 - Math.trunc(lazyObj.value / 4);
        this.mesh.program.uniforms.uCurrentX.value = x;
        this.mesh.program.uniforms.uCurrentY.value = y;
        this.render();
      },
    });
  }

  render() {
    this.renderer.render({ scene: this.mesh });
  }
}

export default new Stage();
