<template>
  <transition @enter="enter" @leave="leave" appear>
    <div class="app-loader loader" v-if="$store.state.showLoader">
      <BaseTitleText :content="$translate('loader').titleText" isBig />

      <BaseCta
        @click.native="clickHandler"
        :content="$translate('loader').cta"
      />
    </div>
  </transition>
</template>

<script>
import BaseTitleText from '@/components/BaseTitleText';
import BaseCta from '@/components/BaseCta';
import { enterBlockText, leaveBlockText } from '@/scripts/transitions/blockText';
import { transitions } from '@/scripts/transitions';

export default {
  name: 'Home',

  components: {
    BaseTitleText,
    BaseCta,
  },

  methods: {
    enter(el, done) {
      enterBlockText(el).delay(0.1).then(done);
    },
    leave(el, done) {
      const view = document.querySelector('.view');
      leaveBlockText(el).then(done);
      transitions.get(`loader-${view.dataset.id}`)(view);
    },
    clickHandler() {
      this.$audio.play('background');
      this.$audio.play('click');
      this.$store.commit('REMOVE_LOADER');
      this.$sendEvent('Start Experience');
    },
  },
};
</script>
