import { gsap } from 'gsap';
import Stage from '@/stage';
import CardsEngine from '../CardsEngine';
import { initBlockText, enterBlockText } from './blockText';
import { enter } from '../animations';

export function loaderToHome() {
  const tl = gsap.timeline();

  tl.addLabel('start')
    .add(() => CardsEngine.toHome(), 'start+=0.3');

  return tl;
}

export function loaderToScene(to) {
  const tl = gsap.timeline();

  initBlockText(to);

  tl.addLabel('start')
    .add(() => CardsEngine.hide(), 'start')
    .add(() => Stage.animate(to.dataset.scene, 'enter'), 'start+=0.5')
    .add(enterBlockText(to), 'start+=1');

  return tl;
}

export function loaderToGame(to) {
  const { game } = to.dataset;
  const tl = gsap.timeline();

  tl.addLabel('start')
    .add(() => CardsEngine.hide(), 'start')
    .add(enter.get(game)(), 'start+=1')
    .add(enter.get('fade')(to.querySelector('.game__footer')), 'start+=1.5');

  return tl;
}

export function loaderToError(to) {
  const tl = gsap.timeline();
  initBlockText(to);

  tl.addLabel('start')
    .add(() => CardsEngine.hide(), 'start')
    .add(enterBlockText(to), 'start+=1');

  return tl;
}
