import { loadTexture } from '@/scripts/utils';
import screen from '@/scripts/screen';

class Scene {
  constructor(id, texture) {
    this.id = id;

    this.texture = texture;
    this.ratio = 0;
  }

  calcRatio() {
    return {
      x: Math.min(screen.ratio.x / (1920 / 1080), 1.0),
      y: Math.min(screen.ratio.y / (1080 / 1920), 1.0),
    };
  }

  resize() {
    this.ratio = this.calcRatio(this.texture);
  }

  async load() {
    await loadTexture(this.texture).then((texture) => {
      this.texture = texture;
      this.resize();
    });
  }
}

export default Scene;
