import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import audio from './scripts/audio';
import { isTouchDevice, sendGAEvent } from './scripts/utils';

import EventBus from './scripts/eventBus';
import translate from './scripts/translate';

Vue.config.productionTip = false;

Vue.prototype.$translate = (key) => translate(key, store.state.lang);
Vue.prototype.$isMobile = (('ontouchstart' in window) || window.DocumentTouch);
Vue.prototype.$eventBus = EventBus;
Vue.prototype.$sendEvent = sendGAEvent;
Vue.prototype.$isTouchDevice = isTouchDevice();
Vue.prototype.$audio = audio;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

// Egg and cikken mystery
console.log('["🥚", "🐓"].sort();');
console.log('> Array [ "🐓", "🥚" ]');
console.log('© Adoratorio', '\n', 'Sound: Kerry Muzzey - Snow (Piano Solo)', '\n', 'Musicbed licenses - View: https://enigma.ferragamo.com/assets/docs/audio-license.pdf');
