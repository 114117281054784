export default {
  lang: 'en',
  cardsEvenetDisable: false,
  showLoader: true,
  playSound: true,
  konami: '',
  phase: 'home',

  gameEnded: {
    memory: window.localStorage.getItem('ferragamo_games_memory:finished') === '1',
    crosswords: window.localStorage.getItem('ferragamo_games_crosswords:finished') === '1',
    connects: window.localStorage.getItem('ferragamo_games_connects:finished') === '1',
  },
};
