import { gsap } from 'gsap';
import Stage from '@/stage';
import CardsEngine from '../CardsEngine';
import { initBlockText, leaveBlockText } from './blockText';
import { init, enter } from '../animations';

export function setScene(el) {
  initBlockText(el);
}

export function sceneToHome(from) {
  const tl = gsap.timeline();

  tl.addLabel('start')
    .add(leaveBlockText(from), 'start')
    .add(() => Stage.animate('', 'leave'), 'start+=0.3')
    .add(() => CardsEngine.toHome(), 'start+=1.0');

  return tl;
}

export function sceneToGame(from, to) {
  const tl = gsap.timeline();
  const { game } = to.dataset;
  const text = to.querySelector('.game__footer');

  init.get(game)(to);
  init.get('fade')(text);

  tl.addLabel('start')
    .add(leaveBlockText(from), 'start')
    .add(() => Stage.animate('', 'leave'), 'start+=1.3')
    .add(enter.get(game)(), 'start+=1.8')
    .add(enter.get('fade')(text), 'start+=2.5');

  return tl;
}
