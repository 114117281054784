import gsap from 'gsap';
import { fadeLeave } from './fade';

let letters;
let stage;
let words;
const grid = [16, 12];

export function crosswordsInit(el) {
  stage = el.querySelector('.game__stage');
  letters = el.querySelectorAll('.game__letter');
  words = el.querySelectorAll('.game__list > span > span');

  gsap.set(stage, { opacity: 0 });
  gsap.set(letters, { opacity: 0 });
  gsap.set(words, { opacity: 0, y: 15 });
}

export function crosswordsAnim() {
  return gsap.timeline()
    .to(stage, {
      opacity: 1,
      duration: 1,
      ease: 'power1.inOut',
    }, 0)
    .to(letters, {
      opacity: 1,
      duration: 0.8,
      ease: 'sine.inOut',
      stagger: {
        each: 0.05,
        from: 'start',
        grid,
      },
    }, 0.15)
    .to(words, {
      opacity: 1,
      y: 0,
      duration: 1,
      ease: 'power3.inOut',
      stagger: 0.1,
    }, 0.5);
}

export function crosswordsOut(el) {
  const text = el.querySelector('.game__footer');

  return gsap.timeline()
    .to(letters, {
      scale: 0.4,
      duration: 0.6,
      ease: 'sine.in',
    })
    .to(stage, {
      opacity: 0,
      duration: 0.8,
      ease: 'power1.out',
    }, 0.3)
    .to(words, {
      opacity: 0,
      y: -20,
      duration: 0.6,
      ease: 'power3.inOut',
    }, 0.3)
    .add(fadeLeave(text), 0.5);
}
