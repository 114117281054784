export default {
  meta: {
    title: 'The Metropolitan Enigma - Salvatore Ferragamo',
    description: 'サルヴァトーレ フェラガモの「Enigma(謎)」を解き、特別なサプライズ体験を。',
  },

  header: {
    back: '戻る',
    collection: '2021年春夏コレクション',
  },

  nextGameCta: '次の謎解きへ',
  endGameCta: '最後の謎解きをする',

  CookieBar: {
    text: 'Ferragamo.comは、自社クッキーおよび第三者クッキーを使用しています。これは当サイトの正しい機能を保証し、お客様の同意を得たうえで統計的な測定をする、カスタマイズしたウエブ体験を提供する、広告メッセージを配信するためのものです。詳細について、また設定変更については、「オプション管理」ボタンをクリック下さい。',
    manage_options: 'オプション管理',
    accept: '同意します',
    code: 'en_31150',
    link: 'https://www.ferragamo.com/shop/jpn/ja/sf/legal/cookie#cookieSettings',
  },

  loader: {
    titleText: {
      title: 'The Metropolitan Enigma',
      text: 'サルヴァトーレ フェラガモの「Enigma(謎)」を解き、特別なサプライズ体験を。<br> 名高いイタリア人映画監督 ルカ・グアダニーノがクリエイティブ ディレクターのポール・アンドリューとコラボレーションした2021年春夏広告キャンペーンムービーの撮影地となったミラノの街角の各地を訪ね、謎を解いてください。',
    },
    cta: '謎解きを始める',
  },

  idroscalo: {
    titleText: {
      title: 'Parco <br class="only-mobile"> dell’Idroscalo',
      text: 'これは神経衰弱ゲーム。解決の鍵は何を探しているのか理解すること。<br> ヒントを集めて謎解きをしましょう。',
    },
    cta: 'ゲームを始める',
    nextLink: 'idroscalo/memory',
  },

  memory: {
    inGame: '記憶力は謎解きを進めるうえで最も大切な要素です。',
    titleText: {
      title: 'Your memory is on point!',
      text: '一歩解決に近づきました。',
    },
    nextLink: 'via-chiossetto',
  },

  'via-chiossetto': {
    titleText: {
      title: 'Via <br class="only-mobile"> Chiossetto',
      text: '聞き込みというのは予想よりはるかにテクニックが要求されます。すべてのキーワードを見逃さないようにしましょう。',
    },
    cta: '答えを探す',
    nextLink: 'via-chiossetto/crosswords',
  },

  crosswords: {
    inGame: '上の4つのキーワードを文字盤から探し、その文字をドラッグしてください。',
    letters: [
      ['T', 'B', 'S', 'Y', 'B', 'E', 'O', 'E', 'D', 'W', 'U'],
      ['E', 'N', 'V', 'E', 'N', 'I', 'G', 'M', 'A', 'N', 'J'],
      ['C', 'C', 'G', 'B', 'C', 'C', 'V', 'N', 'I', 'K', 'B'],
      ['H', 'Z', 'I', 'W', 'Y', 'C', 'B', 'Z', 'E', 'Q', 'C'],
      ['N', 'T', 'V', 'A', 'S', 'M', 'V', 'B', 'J', 'X', 'E'],
      ['I', 'E', 'J', 'A', 'Z', 'G', 'X', 'L', 'I', 'F', 'E'],
      ['C', 'R', 'U', 'S', 'R', 'P', 'V', 'M', 'W', 'P', 'F'],
      ['O', 'B', 'X', 'Y', 'F', 'F', 'G', 'G', 'V', 'P', 'I'],
      ['L', 'P', 'Z', 'B', 'E', 'A', 'U', 'T', 'Y', 'O', 'C'],
      ['O', 'J', 'B', 'Q', 'C', 'F', 'P', 'H', 'K', 'U', 'H'],
      ['R', 'F', 'K', 'Y', 'E', 'W', 'G', 'A', 'H', 'V', 'S'],
    ],
    words: ['Life', 'Enigma', 'Beauty', 'Technicolor'],
    titleText: {
      title: 'You are a smart detective',
      text: '一歩謎の解決に近づきました。',
    },
    nextLink: 'galleria-strasburgo',
  },

  'galleria-strasburgo': {
    titleText: {
      title: 'Galleria <br class="only-mobile"> Strasburgo',
      text: '良い探偵は点と点を結びつける <br> 思考に優れています。',
    },
    cta: '容疑者を追う',
    nextLink: 'galleria-strasburgo/connects',
  },

  connects: {
    tutorial: 'Drag the Gancini and rotate the stage to connect the two parts',
    inGame: '凹型のガンチーニをバー上で動かしたり、マップを回転させながら、凸型のガンチーニと繋げてください。',
    titleText: {
      title: 'Always good to play smart!',
      text: 'また一歩解決に近づきました。',
    },
    nextLink: 'idroscalo',
  },

  'end-game': {
    titleText: {
      title: 'There’s one last enigma to solve...',
      text: 'さあ、最後の謎解きをしましょう。<br> ゴールはもうすぐそこです。 <br> 以下のリンクから最後のENIGMA(謎)に進んでください。ささやかな特典もご用意しています。',
    },
    nextLink: 'https://www.ferragamo.com/enigma',
  },

  404: {
    message: 'Something went wrong',
    cta: 'Back to homepage',
  },

  rotate: {
    message: 'Please rotate your device',
    thanks: 'Thank you',
  },
};
