export default {
  meta: {
    title: 'O Enigma Metropolitano - Salvatore Ferragamo',
    description: 'Resolva o Enigma Salvatore Ferragamo e desfrute de algumas surpresas especiais.',
  },

  header: {
    back: 'Voltar para a  home',
    collection: 'Ver PV21',
  },

  nextGameCta: 'Jogar o Próximo Jogo',
  endGameCta: 'Resolva o último enigma',

  CookieBar: {
    text: 'Ferragamo.com uses its own and third party cookies to ensure the proper functioning of the website and - with your consent - for statistical purposes and to provide you with a personalized experience, as well as to send you advertising communications. For more information and to change your preferences, click on the "Manage Options" button.',
    manage_options: 'Manage options',
    accept: 'I Accept',
    code: 'es_31151',
    link: 'https://www.ferragamo.com/shop/eu/en/sf/legal/cookie-policy#cookieSettings',
  },

  loader: {
    titleText: {
      title: 'O Enigma Metropolitano',
      text: 'Resolva o Enigma Salvatore Ferragamo e desfrute de algumas surpresas especiais.<br>Jogue conosco pelas ruas de Milão, cenário do exclusivo fashion film PV21, dirigido pelo aclamado diretor italiano Luca Guadagnino, em colaboração com nosso Diretor Criativo Paul Andrew.',
    },
    cta: 'Comece a Investigação',
  },

  idroscalo: {
    titleText: {
      title: 'Parco <br class="only-mobile"> dell’Idroscalo',
      text: 'Para resolver todos os mistérios, é fundamental saber o que está à procura.<br>A astúcia é reconhecer os indícios e encontrá-los!',
    },
    cta: 'Comece a Resolver',
    nextLink: 'idroscalo/memory',
  },

  memory: {
    inGame: 'A memória e a intuição pura são as ferramentas mais confiáveis dos detetives!',
    titleText: {
      title: 'Sua memória é ótima!',
      text: 'Você está a um passo de resolver o Enigma.',
    },
    nextLink: 'via-chiossetto',
  },

  'via-chiossetto': {
    titleText: {
      title: 'Via <br class="only-mobile"> Chiossetto',
      text: 'Interrogar os suspeitos é muito mais complicado do que se pensa.<br>É o momento de descobrir se você é capaz!',
    },
    cta: 'Busque as respostas',
    nextLink: 'via-chiossetto/crosswords',
  },

  crosswords: {
    inGame: 'É importante fazer a pergunta certa, mas é muito mais importante buscar as respostas certas!',
    letters: [
      ['T', 'B', 'S', 'Y', 'B', 'E', 'O', 'E', 'D', 'W', 'U'],
      ['E', 'N', 'V', 'E', 'N', 'I', 'G', 'M', 'A', 'N', 'J'],
      ['C', 'C', 'G', 'B', 'C', 'C', 'V', 'N', 'I', 'K', 'B'],
      ['H', 'Z', 'I', 'W', 'Y', 'C', 'B', 'Z', 'E', 'Q', 'C'],
      ['N', 'T', 'V', 'A', 'S', 'M', 'V', 'B', 'J', 'X', 'E'],
      ['I', 'E', 'J', 'A', 'Z', 'G', 'X', 'L', 'I', 'F', 'E'],
      ['C', 'R', 'U', 'S', 'R', 'P', 'V', 'M', 'W', 'P', 'F'],
      ['O', 'B', 'X', 'Y', 'F', 'F', 'G', 'G', 'V', 'P', 'I'],
      ['L', 'P', 'Z', 'B', 'E', 'A', 'U', 'T', 'Y', 'O', 'C'],
      ['O', 'J', 'B', 'Q', 'C', 'F', 'P', 'H', 'K', 'U', 'H'],
      ['R', 'F', 'K', 'Y', 'E', 'W', 'G', 'A', 'H', 'V', 'S'],
    ],
    words: ['Life', 'Enigma', 'Beauty', 'Technicolor'],
    titleText: {
      title: 'Você é um detetive esperto',
      text: 'Você está a um passo de resolver o Enigma.',
    },
    nextLink: 'galleria-strasburgo',
  },

  'galleria-strasburgo': {
    titleText: {
      title: 'Galleria <br class="only-mobile"> Strasburgo',
      text: 'Um bom detetive deve ser capaz de unir as pistas.',
    },
    cta: 'Siga o suspeito',
    nextLink: 'galleria-strasburgo/connects',
  },

  connects: {
    tutorial: 'Arraste  o  Gancini e gire o palco para conectar as duas partes',
    inGame: 'Use o mapa a seu favor e capture o suspeito!',
    titleText: {
      title: 'É sempre bom jogar de maneira inteligente!',
      text: 'Você está a um passo de resolver o Enigma.',
    },
    nextLink: 'idroscalo',
  },

  'end-game': {
    titleText: {
      title: 'Há um último enigma a ser resolvido...',
      text: 'O sucesso está muito próximo!<br>Siga o elo para resolver o último enigma e requeira suas surpresas!',
    },
    nextLink: 'https://www.ferragamo.com/enigma',
  },

  404: {
    message: 'Aconteceu algo errado.',
    cta: 'Voltar para a  home',
  },

  rotate: {
    message: 'Gire seu dispositivo',
    thanks: 'Obrigado',
  },
};
