import { gsap } from 'gsap';
import store from '@/store';
import CardsItem from './CardsItems';
import cardsData from '../assets/cardsData.json';

class CardsEngine {
  constructor() {
    this.inTransition = false;
    this.items = [];
    this.ctx = null;
    this.audio = null;
    this.endGame = false;
  }

  init(ctx, audio) {
    this.ctx = ctx;
    this.audio = audio;
    [...ctx.querySelectorAll('.card')].forEach((card, i) => {
      cardsData[i].index = i;
      this.items.push(new CardsItem(card, cardsData[i]));
      gsap.set(card, { zIndex: Math.abs(i - 2) });
    });

    const endedArray = Object.values(store.state.gameEnded);
    this.endGame = endedArray.every((game) => game);
  }

  toHover(i) {
    this.audio.play('swoosh');
    this.items.forEach((item) => item.toHover(i));
  }

  toHome(fast = false) {
    setTimeout(() => { this.audio.play('swoosh'); }, fast ? 0 : 900);

    if (this.endGame && store.state.phase === 'next-card') {
      const toHide = this.items.findIndex((item) => item.phase === 'toNext');
      this.items[toHide]
        .hide()
        .then(() => {
          this.items.forEach((item) => item.toHome(fast, this.endGame));
        });
    } else {
      this.items.forEach((item) => item.toHome(fast));
    }
    store.commit('SET_PHASE', 'home');
  }

  hide() {
    this.audio.play('swoosh');
    this.items.forEach((item) => item.hide());
    store.commit('SET_PHASE', 'game');
  }

  toScene(id) {
    this.audio.play('swoosh');
    const i = this.items.findIndex((item) => item.id === id);

    this.items.forEach((item) => item.toScene(i));

    store.commit('SET_PHASE', 'scene');
  }

  toNextCard(id) {
    const endedArray = Object.values(store.state.gameEnded);
    this.endGame = endedArray.every((game) => game);

    setTimeout(() => { this.audio.play('swoosh'); }, 800);
    const currentIndex = this.items.findIndex((item) => item.id === id);

    this.items[currentIndex].toNext(this.endGame);
    store.commit('SET_PHASE', 'next-card');
  }
}

export default new CardsEngine();
