export default {
  meta: {
    title: 'Das Mailand-Rätselspiel - Salvatore Ferragamo',
    description: 'Löse Salvatore Ferragamos Rätsel, dich erwarten ganz besondere Überraschungen.',
  },

  header: {
    back: 'Wieder zuhause',
    collection: 'Schau SS21',
  },

  nextGameCta: 'Weiter spielen',
  endGameCta: 'Letztes Rätsel lösen',

  CookieBar: {
    text: 'Ferragamo.com verwendet eigene und Drittanbieter-Cookies, um das ordnungsgemäße Funktionieren der Website zu gewährleisten und - mit Ihrem Einverständnis - für statistische Zwecke und um Ihnen ein personalisiertes Erlebnis zu bieten sowie um Ihnen Werbemitteilungen zu senden. Für weitere Informationen und um Ihre Einstellungen zu ändern, klicken Sie auf die Schaltfläche „Optionen verwalten“.',
    manage_options: 'OPTIONEN VERWALTEN',
    accept: 'EINVERSTANDEN',
    code: 'de_30650',
    link: 'https://www.ferragamo.com/shop/eu/de/sf/legal/cookie-policy#cookieSettings',
  },

  loader: {
    titleText: {
      title: 'Das Mailand-Rätselspiel',
      text: 'Löse Salvatore Ferragamos Rätsel, dich erwarten ganz besondere Überraschungen.<br>Spiele mit uns in den Straßen von Mailand, wo der exklusive Modefilm SS21 unter der Regie des beliebten italienischen Filmemachers Luca Guadagnino in Zusammenarbeit mit unserem Creative Director Paul Andrew gedreht wurde.',
    },
    cta: 'Avvia l’indagine',
  },

  idroscalo: {
    titleText: {
      title: 'Parco <br class="only-mobile"> dell’Idroscalo',
      text: 'Um Geheimnisse zu lösen, muss man vor allem wissen, wonach man sucht.<br>Wer klug ist, sammelt Hinweise und findet es heraus!',
    },
    cta: 'Lösen',
    nextLink: 'idroscalo/memory',
  },

  memory: {
    inGame: 'Gedächtnis und reine Intuition sind die zuverlässigsten Werkzeuge eines Detektivs!',
    titleText: {
      title: 'Dein Gedächtnis ist perfekt!',
      text: 'Du bist des Rätsels Lösung einen Schritt näher.',
    },
    nextLink: 'via-chiossetto',
  },

  'via-chiossetto': {
    titleText: {
      title: 'Via <br class="only-mobile"> Chiossetto',
      text: 'Verdächtige verhören ist viel schwieriger, als man denkt.<br>Zeit festzustellen, ob du ihre Gedanken lesen kannst!',
    },
    cta: 'Antworten suchen',
    nextLink: 'via-chiossetto/crosswords',
  },

  crosswords: {
    inGame: 'Man muss die richtigen Fragen stellen, aber vor allem, die richtigen Antworten finden!',
    letters: [
      ['T', 'B', 'S', 'Y', 'B', 'E', 'O', 'E', 'D', 'W', 'U'],
      ['E', 'N', 'V', 'E', 'N', 'I', 'G', 'M', 'A', 'N', 'J'],
      ['C', 'C', 'G', 'B', 'C', 'C', 'V', 'N', 'I', 'K', 'B'],
      ['H', 'Z', 'I', 'W', 'Y', 'C', 'B', 'Z', 'E', 'Q', 'C'],
      ['N', 'T', 'V', 'A', 'S', 'M', 'V', 'B', 'J', 'X', 'E'],
      ['I', 'E', 'J', 'A', 'Z', 'G', 'X', 'L', 'I', 'F', 'E'],
      ['C', 'R', 'U', 'S', 'R', 'P', 'V', 'M', 'W', 'P', 'F'],
      ['O', 'B', 'X', 'Y', 'F', 'F', 'G', 'G', 'V', 'P', 'I'],
      ['L', 'P', 'Z', 'B', 'E', 'A', 'U', 'T', 'Y', 'O', 'C'],
      ['O', 'J', 'B', 'Q', 'C', 'F', 'P', 'H', 'K', 'U', 'H'],
      ['R', 'F', 'K', 'Y', 'E', 'W', 'G', 'A', 'H', 'V', 'S'],
    ],
    words: ['Life', 'Enigma', 'Beauty', 'Technicolor'],
    titleText: {
      title: 'Schlau kombiniert!',
      text: 'Du bist des Rätsels Lösung einen Schritt näher.',
    },
    nextLink: 'galleria-strasburgo',
  },

  'galleria-strasburgo': {
    titleText: {
      title: 'Galleria <br class="only-mobile"> Strasburgo',
      text: 'Für gute Detektive ist es ein Leichtes, die Punkte zu verbinden.',
    },
    cta: 'Verdächtige jagen',
    nextLink: 'galleria-strasburgo/connects',
  },

  connects: {
    tutorial: 'Gancini-Zeichen ziehen und Szene drehen, um die beiden Teile zu verbinden.',
    inGame: 'Nutze die Karte zu deinem Vorteil, um den Verdächtigen einzusperren!',
    titleText: {
      title: 'Es ist immer gut, klug zu spielen!',
      text: 'Du bist des Rätsels Lösung einen Schritt näher.',
    },
    nextLink: 'idroscalo',
  },

  'end-game': {
    titleText: {
      title: 'Nun noch ein letztes Rätsel zum Lösen…',
      text: 'Der Sieg steht vor der Tür!<br>Folge dem Link, um das letzte Rätsel zu lösen und fordere deine Überraschungen an!',
    },
    nextLink: 'https://www.ferragamo.com/enigma',
  },

  404: {
    message: 'Etwas ist schief gelaufen',
    cta: 'Wieder zuhause',
  },

  rotate: {
    message: 'Bitte drehen Sie  ihr Gerät.',
    thanks: 'Danke',
  },
};
