/* eslint-disable */
window.cc_i18n =
      {
        strings: {
          cc_cd_lbl_text1: "Ferragamo.com uses its own and third party cookies to ensure the proper functioning of the website and - with your consent - for statistical purposes and to provide you with a personalized experience, as well as to send you advertising communications. For more information and to change your preferences, click on the \"Manage Options\" button."
        },

        // java local format
        strings_it: {
          cc_cd_lbl_text1: "Ferragamo.com utilizza cookie propri e di terze parti, per garantire il corretto funzionamento del sito e â€“ previo tuo consenso â€“ per finalitÃ  statistiche e per fornirti unâ€™esperienza e comunicazioni pubblicitarie personalizzate. Per maggiori informazioni e per modificare le tue preferenze clicca sul pulsante \â€œGestisci Opzioni\â€"
        },
        strings_en: {
          cc_cd_lbl_text1: "Ferragamo.com uses its own and third party cookies to ensure the proper functioning of the website and - with your consent - for statistical purposes and to provide you with a personalized experience, as well as to send you advertising communications. For more information and to change your preferences, click on the \"Manage Options\" button."
        },
        strings_de: {
          cc_cd_lbl_text1: "Ferragamo.com verwendet eigene und Drittanbieter-Cookies, um das ordnungsgemÃ¤ÃŸe Funktionieren der Website zu gewÃ¤hrleisten und - mit Ihrem EinverstÃ¤ndnis - fÃ¼r statistische Zwecke und um Ihnen ein personalisiertes Erlebnis zu bieten sowie um Ihnen Werbemitteilungen zu senden. FÃ¼r weitere Informationen und um Ihre Einstellungen zu Ã¤ndern, klicken Sie auf die SchaltflÃ¤che â€žOptionen verwaltenâ€œ."
        },
        strings_fr: {
          cc_cd_lbl_text1: "Ferragamo.com utilise ses propres cookies et ceux de tiers pour assurer le bon fonctionnement du site Internet et - avec votre consentement - Ã  des fins statistiques et pour vous offrir une expÃ©rience personnalisÃ©e, ainsi que pour vous envoyer des communications publicitaires. Pour plus d'informations et pour modifier vos prÃ©fÃ©rences, cliquez sur le bouton Â«Â GÃ©rer les optionsÂ Â»."
        },
        strings_es: {
          cc_cd_lbl_text1: "Ferragamo.com utiliza cookies propias y de terceros para garantizar el correcto funcionamiento del sitio web y, con su consentimiento, con fines estadÃ­sticos y para brindarle una experiencia personalizada, asÃ­ como para enviarle comunicaciones publicitarias. Para obtener mÃ¡s informaciÃ³n y cambiar sus preferencias, haga clic en el botÃ³n \"Administrar opciones\"."
        },
        /*Cinese tradizionale hong kong, taiwan*/
        strings_zh_hkg: {
          cc_cd_lbl_text1: "Ferragamo.comç‚ºç¢ºä¿æœ¬ç¶²ç«™çš„æ­£å¸¸é‹ç‡Ÿï¼Œä¸¦ï¼ˆåœ¨ç¶“æ‚¨åŒæ„å¾Œï¼‰åŸºæ–¼çµ±è¨ˆç›®çš„å’Œå‘æ‚¨æä¾›å€‹æ€§åŒ–é«”é©—èˆ‡ç™¼é€å»£å‘Šè³‡è¨Šçš„éœ€è¦ï¼Œä½¿ç”¨è‡ªèº«å’Œç¬¬ä¸‰æ–¹çš„cookieã€‚è¦çž­è§£æ›´å¤šè³‡è¨Šå’Œæ›´æ”¹æ‚¨çš„é¦–é¸é …ï¼Œè«‹é»žæ“Šâ€œç®¡ç†é¸é …â€æŒ‰éˆ•ã€‚"
        },
        strings_zh_twn: {
          cc_cd_lbl_text1: "Ferragamo.comç‚ºç¢ºä¿æœ¬ç¶²ç«™çš„æ­£å¸¸é‹ç‡Ÿï¼Œä¸¦ï¼ˆåœ¨ç¶“æ‚¨åŒæ„å¾Œï¼‰åŸºæ–¼çµ±è¨ˆç›®çš„å’Œå‘æ‚¨æä¾›å€‹æ€§åŒ–é«”é©—èˆ‡ç™¼é€å»£å‘Šè³‡è¨Šçš„éœ€è¦ï¼Œä½¿ç”¨è‡ªèº«å’Œç¬¬ä¸‰æ–¹çš„cookieã€‚è¦çž­è§£æ›´å¤šè³‡è¨Šå’Œæ›´æ”¹æ‚¨çš„é¦–é¸é …ï¼Œè«‹é»žæ“Šâ€œç®¡ç†é¸é …â€æŒ‰éˆ•ã€‚"
        },
        /*Cinese  semplificato (per cina, singapore*/
        strings_zh_chn: {
          cc_cd_lbl_text1: "Ferragamo.comä¸ºç¡®ä¿æœ¬ç½‘ç«™çš„æ­£å¸¸è¿è¥ï¼Œå¹¶ï¼ˆåœ¨ç»æ‚¨åŒæ„åŽï¼‰åŸºäºŽç»Ÿè®¡ç›®çš„å’Œå‘æ‚¨æä¾›ä¸ªæ€§åŒ–ä½“éªŒä¸Žå‘é€å¹¿å‘Šä¿¡æ¯çš„éœ€è¦ï¼Œä½¿ç”¨è‡ªèº«å’Œç¬¬ä¸‰æ–¹çš„cookieã€‚è¦äº†è§£æ›´å¤šä¿¡æ¯å’Œæ›´æ”¹æ‚¨çš„é¦–é€‰é¡¹ï¼Œè¯·ç‚¹å‡»â€œç®¡ç†é€‰é¡¹â€æŒ‰é’®ã€‚"
        },
        strings_zh_sgp: {
          cc_cd_lbl_text1: "Ferragamo.comä¸ºç¡®ä¿æœ¬ç½‘ç«™çš„æ­£å¸¸è¿è¥ï¼Œå¹¶ï¼ˆåœ¨ç»æ‚¨åŒæ„åŽï¼‰åŸºäºŽç»Ÿè®¡ç›®çš„å’Œå‘æ‚¨æä¾›ä¸ªæ€§åŒ–ä½“éªŒä¸Žå‘é€å¹¿å‘Šä¿¡æ¯çš„éœ€è¦ï¼Œä½¿ç”¨è‡ªèº«å’Œç¬¬ä¸‰æ–¹çš„cookieã€‚è¦äº†è§£æ›´å¤šä¿¡æ¯å’Œæ›´æ”¹æ‚¨çš„é¦–é€‰é¡¹ï¼Œè¯·ç‚¹å‡»â€œç®¡ç†é€‰é¡¹â€æŒ‰é’®ã€‚"
        },
        strings_ja: {
          cc_cd_lbl_text1: "Ferragamo.comã¯ã€è‡ªç¤¾ã‚¯ãƒƒã‚­ãƒ¼ãŠã‚ˆã³ç¬¬ä¸‰è€…ã‚¯ãƒƒã‚­ãƒ¼ã‚’ä½¿ç”¨ã—ã¦ã„ã¾ã™ã€‚ã“ã‚Œã¯å½“ã‚µã‚¤ãƒˆã®æ­£ã—ã„æ©Ÿèƒ½ã‚’ä¿è¨¼ã—ã€ãŠå®¢æ§˜ã®åŒæ„ã‚’å¾—ãŸã†ãˆã§çµ±è¨ˆçš„ãªæ¸¬å®šã‚’ã™ã‚‹ã€ã‚«ã‚¹ã‚¿ãƒžã‚¤ã‚ºã—ãŸã‚¦ã‚¨ãƒ–ä½“é¨“ã‚’æä¾›ã™ã‚‹ã€åºƒå‘Šãƒ¡ãƒƒã‚»ãƒ¼ã‚¸ã‚’é…ä¿¡ã™ã‚‹ãŸã‚ã®ã‚‚ã®ã§ã™ã€‚è©³ç´°ã«ã¤ã„ã¦ã€ã¾ãŸè¨­å®šå¤‰æ›´ã«ã¤ã„ã¦ã¯ã€ã€Œã‚ªãƒ—ã‚·ãƒ§ãƒ³ç®¡ç†ã€ãƒœã‚¿ãƒ³ã‚’ã‚¯ãƒªãƒƒã‚¯ä¸‹ã•ã„ã€‚"
        },
        strings_ko: {
          cc_cd_lbl_text1: "Ferragamo.com&#51008; &#44256;&#44061;&#50640;&#44172; &#44305;&#44256;&#47484; &#51204;&#45804;&#54616;&#44592; &#50948;&#54620; &#47785;&#51201; &#51060;&#50808;&#50640;&#46020; &#53685;&#44228;&#51201; &#47785;&#51201;&#44284; &#44060;&#51064;&#54868;&#46108; &#44221;&#54744;&#51012; &#51228;&#44277;&#54616;&#44592; &#50948;&#54616;&#50668; &#49324;&#50857;&#51088;&#51032; &#46041;&#51032; &#54616;&#50640; &#50937;&#49324;&#51060;&#53944;&#51032; &#51201;&#51208;&#54620; &#44592;&#45733;&#51012; &#45812;&#48372;&#54624; &#47785;&#51201;&#51004;&#47196;  &#51088;&#49324; &#48143; &#53440;&#49324;&#51032; &#53216;&#53412;&#47484; &#49324;&#50857;&#54633;&#45768;&#45796;. &#51060;&#50640; &#44288;&#54620; &#51328; &#45908; &#51088;&#49464;&#54620; &#51221;&#48372; &#48143; &#44592;&#48376; &#49444;&#51221; &#48320;&#44221;&#51012; &#50896;&#54616;&#49884;&#45716; &#48516;&#46308;&#51008; &#8220;&#50741;&#49496;&#44288;&#47532;&#8221; &#48260;&#53948;&#51012; &#53364;&#47533;&#54616;&#49901;&#49884;&#50724;."
        }
      };
