<template>
  <div class="app-cursor cursor">
    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <circle cx="50%" cy="50%" r="40" stroke="#B9B9B9" stroke-width="1" fill="none" />
      <circle cx="50%" cy="50%" r="4" fill="#B9B9B9" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'AppCursor',
};
</script>

<style lang="scss" scoped>
.cursor {
  position: absolute;
  top: 0;
  left: 0;
  width: 6.25vw;
  min-width: 80px;

  pointer-events: none;
  z-index: z-index(cursor);

  svg {
    display: block;
    width: 100%;
  }
}
</style>
