import { gsap } from 'gsap';
import CardsEngine from '../CardsEngine';
import { initBlockText, leaveBlockText } from './blockText';

export function setError(el) {
  initBlockText(el);
}

export function errorToHome(from) {
  const tl = gsap.timeline();

  tl.addLabel('start')
    .add(leaveBlockText(from), 'start')
    .add(() => CardsEngine.toHome(), 'start+=1.0');

  return tl;
}
