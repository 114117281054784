<template>
  <transition appear name="fade">
    <div class="app-shadows shadows" :class="{ 'shadows--op' : isFirefox }">
      <transition name="fade" appear>
        <img
          v-if="$route.params.game !== undefined"
          key="general"
          class="shadows__image shadows__image--game"
          :src="`/assets/img/base/${isMobile ? 'shadow-game-mbl' : 'shadow-game'}.${isFirefox ? 'png' : 'jpg'}`"
          alt="shadow"
        >
        <img
          v-else
          key="game"
          class="shadows__image shadows__image--base"
          :src="`/assets/img/base/${isMobile ? 'shadow-mbl' : 'shadow'}.${isFirefox ? 'png' : 'jpg'}`"
          alt="shadow"
        >
      </transition>
    </div>
  </transition>
</template>

<script>
import engine from '@/scripts/engine';
import cursor from '@/scripts/cursor';
import screen from '@/scripts/screen';

export default {
  name: 'AppShadows',

  data() {
    return {
      isFirefox: navigator.userAgent.toLowerCase().indexOf('firefox') > -1,
      isMobile: this.$isTouchDevice && window.innerWidth < 768,
    };
  },

  mounted() {
    if (!this.$isTouchDevice) engine.add(() => this.render(), 'shadow-effect');
  },

  methods: {
    render() {
      if (!cursor.ref) return;

      if (!this.$isTouchDevice) {
        const deltaX = (cursor.ref.coords.x - (screen.x / 2)) / (screen.x / 2);
        const deltaY = (cursor.ref.coords.y - (screen.y / 2)) / (screen.y / 2);

        this.$el.style.transform = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
          ? `
            perspective(${screen.x / 2}px)
            skewX(${(2 * deltaY).toFixed(2)}deg)
          `
          : `
            perspective(${screen.x / 2}px)
            rotateY(${(4 * deltaX).toFixed(2)}deg)
            rotateX(${(2 * deltaY).toFixed(2)}deg)
            skewX(${(2 * deltaY).toFixed(2)}deg)
          `;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 5s $ease-base 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.shadows {
  @include full(true);

  backface-visibility: hidden;
  mix-blend-mode: multiply;
  pointer-events: none;

  @supports not (mix-blend-mode: multiply) {
    opacity: 0.1;
  }

  &--op {
    mix-blend-mode: unset;
    opacity: 0.15;
    filter: brightness(30%);
  }

  &__image {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 120vw;
    height: 120vh;

    transform: translate(-50%, -50%)
  }
}
</style>
