<template>
  <header class="app-header header">
    <div>
      <transition @enter="enter" @leave="leave" @css="false">
        <router-link
          v-if="!$store.state.showLoader && $store.state.phase !== 'home'"
          class="header__back"
          :to="`/${$store.state.lang}`"
          @click.native="$audio.play('click')"
          aria-label="Back to prev page"
        >
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 43.5 15.1">
            <g class="arrow" fill="none" stroke="#fff" stroke-width="1px">
              <path d="M7.6-0.1c0,4.2-3.4,7.6-7.6,7.6l0,0c4.2,0,7.6,3.4,7.6,7.6l0,0"/>
              <line x1="0" y1="7.6" x2="43.5" y2="7.6"/>
            </g>
          </svg>
          <p v-html="$translate('header').back" />
        </router-link>
      </transition>
    </div>

    <strong>
      <router-link
        :to="`/${$store.state.lang}`"
        class="header__logo"
        @click.native="onClick"
      >
        <img width="600.1" height="113.6" src="/assets/svg/salvatore-ferragamo-logo.svg" alt="Salvatore Ferragamo logo">
      </router-link>
    </strong>

    <div>
      <BaseCollectionButton top />
    </div>
  </header>
</template>

<script>
import { gsap } from 'gsap';
import BaseCollectionButton from '@/components/BaseCollectionButton';
import { isTouchDevice } from '@/scripts/utils';

export default {
  name: 'AppHeader',

  components: {
    BaseCollectionButton,
  },

  data() {
    return {
      tapNumber: 0,
    };
  },

  // computed: {
  //   gameEnded() {
  //     const endedArray = Object.values(this.$store.state.gameEnded);
  //     return endedArray.every((game) => game);
  //   },
  // },

  methods: {
    setClear() {
      return setTimeout(() => {
        this.tapNumber = 0;
      }, 900);
    },
    onClick() {
      if (isTouchDevice()) {
        this.tapNumber += 1;

        if (this.tapNumber > 2) {
          window.open('https://www.ferragamo.com/enigma', '_blank');
        }

        clearTimeout(this.setClear);
        this.setClear();
      }

      this.$sendEvent('Click on logo');
    },
    enter(el, done) {
      const tl = gsap.timeline({ onComplete: done });

      tl.addLabel('start')
        .from(el.querySelector('p'), {
          autoAlpha: 0,
          x: 20,
          ease: 'power3.out',
          duration: 1.2,
        }, 'start')
        .from(el.querySelector('line'), {
          scaleX: 0,
          duration: 1.2,
          ease: 'power3.out',
          transformOrigin: '100% 50%',
          clearProps: 'all',
        }, 'start+=0.3')
        .from(el.querySelector('path'), {
          scale: 0,
          duration: 1.2,
          ease: 'power3.out',
          transformOrigin: '20% 50%',
          clearProps: 'all',
        }, 'start+=0.6');
    },
    leave(el, done) {
      const tl = gsap.timeline({ onComplete: done });

      tl.addLabel('start')
        .to(el, {
          x: 20,
          opacity: 0,
          duration: 1.2,
          ease: 'power3.out',
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  top: grid-size(1);
  left: 0;
  width: 100%;
  padding: 0 grid-size(1);

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;

  z-index: z-index(header);

  > * {
    &:nth-child(1) {
      grid-column: 1 / 2;
      justify-self: start;
    }
    &:nth-child(2) {
      grid-column: 2 / 3;
      justify-self: center;

      a {
        display: block;
        transform: translateY(5%);
      }

      img {
        width: grid-size(12);
        height: auto;

        @include mq(ipadP) {
          width: grid-size(4);
        }
      }
    }
    &:nth-child(3) {
      grid-column: 3 / -1;
      justify-self: end;
    }
  }

  &__logo--hidden {
    cursor: default;
  }

  &__back {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    svg {
      width: grid-size(0.75);
      margin-right: 10px;
    }
  }

  @include mq($until: ipadP) {
    top: grid-size(2);

    &__back {
      p {
        display: none;
      }

      svg {
        width: grid-size(2);
      }
    }

    .collection-button {
      display: none;
    }
  }

  @include mq($and: $is-not-touch-device) {
    &__back {
      svg {
        transition: transform 0.5s $ease-base;
      }

      &:hover svg {
        transform: translateX(-10px);
      }
    }
  }
}
</style>
