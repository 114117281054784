import {
  loaderToHome,
  loaderToScene,
  loaderToGame,
  loaderToError,
} from './loader';
import homeToScene from './home';
import { setScene, sceneToHome, sceneToGame } from './scene';
import { setGame, gameToHome, gameToScene } from './game';
import { setError, errorToHome } from './error';

const setEnter = new Map();
setEnter.set('scene', setScene);
setEnter.set('game', setGame);
setEnter.set('error', setError);

const transitions = new Map();
transitions.set('loader-home', loaderToHome);
transitions.set('loader-scene', loaderToScene);
transitions.set('loader-game', loaderToGame);
transitions.set('loader-error', loaderToError);
transitions.set('home-scene', homeToScene);
transitions.set('scene-home', sceneToHome);
transitions.set('scene-game', sceneToGame);
transitions.set('game-home', gameToHome);
transitions.set('game-scene', gameToScene);
transitions.set('error-home', errorToHome);

export { setEnter, transitions };
