<template>
  <div class="app">
    <AppHeader />

    <AppBgIllustrations :deltaX="deltaX"/>

    <div
      class="stage"
      :style="$isTouchDevice ? false : { transform: `
        perspective(500px)
        scale(1.1)
        rotateY(${deltaX / 10}deg)
        rotateX(${deltaY / 8.5}deg)
        translateX(${10 + (deltaX * 0.1)}px
      `}"
    />

    <AppShadows />

    <Cards />

    <transition :css="false" @enter="enter" @leave="leave" mode="in-out" appear>
      <router-view
        class="view"
        :key="$route.fullPath"
      />
    </transition>

    <AppLoader />

    <transition name="fade-scale">
      <AppToggleSound v-if="!$store.state.showLoader" />
    </transition>

    <transition name="fade-scale">
      <div v-if="!$store.state.showLoader" class="base-collection-mobile">
        <BaseCollectionButton top />
      </div>
    </transition>

    <AppRotateDevice />

    <AppCookieBar v-if="!$store.state.lang.includes('zh')"/>

    <AppCursor ref="cursor" v-if="!$isTouchDevice" />
  </div>
</template>

<script>
import debounce from 'lodash/debounce';

import AppHeader from '@/components/AppHeader';
import AppBgIllustrations from '@/components/AppBgIllustrations';
import AppShadows from '@/components/AppShadows';
import AppLoader from '@/components/AppLoader';
import AppToggleSound from '@/components/AppToggleSound';
import AppCookieBar from '@/components/AppCookieBar';
import AppCursor from '@/components/AppCursor';
import AppRotateDevice from '@/components/AppRotateDevice';
import BaseCollectionButton from '@/components/BaseCollectionButton';
import Cards from '@/components/Cards';

import Stage from '@/stage';
import cursor from '@/scripts/cursor';
import engine from '@/scripts/engine';
import screen from '@/scripts/screen';
import EventBus from '@/scripts/eventBus';
import disableScroll from '@/scripts/disableScroll';
import { transitions, setEnter } from '@/scripts/transitions';

export default {
  name: 'App',

  components: {
    AppRotateDevice,
    AppHeader,
    AppBgIllustrations,
    AppShadows,
    AppLoader,
    AppToggleSound,
    AppCookieBar,
    AppCursor,
    Cards,
    BaseCollectionButton,
  },

  data() {
    return {
      newView: null,
      fromLoader: true,
      deltaX: 0,
      deltaY: 0,
    };
  },

  created() {
    const { title, description } = this.$translate('meta');
    document.title = title;
    document.getElementsByName('description')[0].setAttribute('content', description);
    document.getElementsByName('og:title')[0].setAttribute('content', title);
    document.getElementsByName('og:description')[0].setAttribute('content', description);
    document.getElementsByName('twitter:title')[0].setAttribute('content', title);
    document.getElementsByName('twitter:description')[0].setAttribute('content', description);
    document.documentElement.lang = this.$store.state.lang;
  },

  mounted() {
    this.resize();
    disableScroll();

    if (!this.$isTouchDevice) {
      cursor.init(this.$refs.cursor.$el, engine);
      engine.add(() => this.render(), 'illustrations-tilt');
    }

    engine.start();
    Stage
      .load()
      .then(() => {
        Stage.appendCanvas(this.$el.querySelector('.stage'));
        Stage.setAudio(this.$audio);
      });

    window.addEventListener('resize', debounce(() => this.resize(), 250));

    this.$audio.init();
    this.$audio.load();

    let clearKonami;
    const setClear = () => {
      clearKonami = setTimeout(() => {
        this.$store.commit('CLEAR_KONAMI_KODE');
      }, 3000);
    };

    document.onkeydown = (ev) => {
      this.$store.commit('ADD_KONAMI_LETTER', ev.key);

      if (this.$store.state.konami.toLowerCase().includes('enigma')) {
        window.open('https://www.ferragamo.com/enigma', '_blank');
      }

      clearTimeout(clearKonami);
      setClear();
    };
  },

  methods: {
    resize() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);

      screen.resize();

      EventBus.$emit('windowResize');
    },

    render() {
      if (!cursor.ref) return;

      this.deltaX = (cursor.ref.coords.x - (screen.x / 2)) / (screen.x / 2).toFixed(2);
      this.deltaY = (cursor.ref.coords.y - (screen.x / 2)) / (screen.x / 2).toFixed(2);
    },

    enter(el, done) {
      this.newView = el;

      if (this.fromLoader) {
        this.fromLoader = false;

        if (setEnter.has(el.dataset.id)) setEnter.get(el.dataset.id)(el);
      }

      done();
    },

    leave(el, done) {
      transitions.get(`${el.dataset.id}-${this.newView.dataset.id}`)(el, this.newView).then(() => {
        if (this.newView.dataset.id === 'home' && this.$store.state.cardsEvenetDisable) this.$store.commit('TOGGLE_CARDS_EVENTS');
        done();
      });
    },
  },
};
</script>

<style lang="scss">
@import '@/scss/base.scss';
@import '@/scss/common.scss';

.app {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;

  background: $color-background;

  &::after {
    content: '';

    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;

    mix-blend-mode: multiply;
    opacity: 0.15;
    background-image: url('/assets/img/base/grain.png');
    pointer-events: none;

    @supports not (mix-blend-mode: multiply) {
      opacity: 0.05;
    }
  }

  .stage,
  .loader,
  .view {
    @include full();
  }

  .stage,
  .home {
    pointer-events: none;
  }

  .view,
  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
  }

  .loader {
    z-index: z-index(loader);
  }
}

.fade-scale-enter-active {
  transition: transform .5s $ease-base 0.5s,
    opacity .5s $ease-base 0.5s;
}

.fade-scale-enter, .fade-scale-leave-to {
  transform: scale(0);
  opacity: 0;
}

.base-collection-mobile {
  display: none;

  @include mq($until: ipadP) {
    display: block;

    position: absolute;
    bottom: grid-size(1);
    left: grid-size(1);
  }
}
</style>
