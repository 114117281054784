<template>
  <transition name="fade-transition" @enter="enter" @leave="leave" :css="false">
    <div class="app-bg-illustrations bg-illustrations" v-if="$route.params.game && !$store.state.showLoader">
      <div
        v-show="illustration.game === $route.params.game"
        v-for="(illustration, i) in illustrations"
        :key="illustration.src"
        :class="['bg-illustrations__image', `bg-illustrations__image--${i % 2 === 0 ? 'left' : 'right'}`]"
      >
        <img
          :src="illustration.src"
          :alt="illustration.alt"
          :style="$isTouchDevice ? false : { transform: `
            perspective(500px)
            rotateY(${deltaX}deg)
            translateX(${((i % 2 === 0 ? -1 : 1) * 10) + (deltaX * 10)}px
          `}"
        >
      </div>
    </div>
  </transition>
</template>

<script>
import { gsap } from 'gsap';

export default {
  name: 'AppBgIllustrations',

  props: {
    deltaX: {
      type: Number,
      required: false,
      default: 0,
    },
  },

  data() {
    return {
      illustrationsList: [
        { src: '/assets/img/illustrations/memory_00.png', alt: 'Illustration', game: 'memory' },
        { src: '/assets/img/illustrations/memory_01.png', alt: 'Illustration', game: 'memory' },
        { src: '/assets/img/illustrations/crosswords_00.png', alt: 'Illustration', game: 'crosswords' },
        { src: '/assets/img/illustrations/crosswords_01.png', alt: 'Illustration', game: 'crosswords' },
        { src: '/assets/img/illustrations/connects_00.png', alt: 'Illustration', game: 'connects' },
        { src: '/assets/img/illustrations/connects_01.png', alt: 'Illustration', game: 'connects' },
      ],
    };
  },

  computed: {
    illustrations() {
      const route = this.$route.params.game;
      return this.illustrationsList.filter((illustration) => illustration.game === route);
    },
  },

  methods: {
    enter(el, done) {
      gsap.from(el, { autoAlpha: 0, duration: 1.2, onComplete: done });
    },
    leave(el, done) {
      gsap.to(el, { autoAlpha: 0, duration: 0.8, onComplete: done });
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-illustrations {
  @include full();
  mix-blend-mode: multiply;
  pointer-events: none;
  opacity: 0.85;

  @supports not (mix-blend-mode: multiply) {
    opacity: 0.1;
  }

  &__image {
    position: absolute;
    height: auto;

    &--left {
      top: -10vh;
      left: 0;
      width: 35vw;

      img {
        transform-origin: 0% 50%;
      }
    }
    &--right {
      bottom: 0;
      right: 0;
      width: 28vw;

      img {
        transform-origin: 100% 50%;
      }
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  @include mq(ipadP) {
    &__image {
      position: absolute;
      top: unset;
      bottom: 0;
      width: auto;
      height: 90%;

      img {
        width: auto;
        height: 100%;
      }
    }
  }
}
</style>
