export default {
  meta: {
    title: '大都會之謎 - Salvatore Ferragamo',
    description: '進入Salvatore Ferragamo的迷宮，體驗意想不到的驚喜。',
  },

  header: {
    back: '返回主頁',
    collection: '觀看2021春夏系列',
  },

  nextGameCta: '下一個游戲',
  endGameCta: '解開最後的謎底',

  CookieBar: {
    code: 'ja_32701',
    link: 'https://www.ferragamo.cn/terms?key=privacy',
  },

  loader: {
    titleText: {
      title: '大都會之謎',
      text: '進入Salvatore Ferragamo的迷宮，體驗意想不到的驚喜。<br>和我們一起來探秘米蘭街市：著名意大利導演Luca Guadagnino與我們的創意總監Paul Andrew在這迷人的街市中，合作拍攝了獨家2021春夏時尚影片。',
    },
    cta: '開始調查',
  },

  idroscalo: {
    titleText: {
      title: 'Parco <br class="only-mobile"> dell’Idroscalo公園',
      text: '要找到謎底，最重要的是您知道要尋找的東西。<br>收集蛛絲馬跡，一探究竟才是明智之舉!',
    },
    cta: '開始搜索',
    nextLink: 'idroscalo/memory',
  },

  memory: {
    inGame: '記憶力和純粹的直覺是偵探人員最值得信賴的工具！',
    titleText: {
      title: '您的記憶真好!',
      text: '您距離揭開謎底更近一步了。',
    },
    nextLink: 'via-chiossetto',
  },

  'via-chiossetto': {
    titleText: {
      title: 'Via <br class="only-mobile"> Chiossetto路',
      text: '想質問懸疑人士可比您所想象的更棘手。<br>是時候看看您能不能讀懂他們了！',
    },
    cta: '尋找答案',
    nextLink: 'via-chiossetto/crosswords',
  },

  crosswords: {
    inGame: '很重要會提出正確的問題，但是找到正確的答案就更重要了！',
    letters: [
      ['消', '黃', '實', '否', '別', '示', '所', '程', '茶', '降', '交'],
      ['職', '拒', '十', '果', '觀', '週', '眼', '難', '單', '競', '望'],
      ['傳', '即', '車', '鍛', '十', '謎', '樣', '默', '漂', '包', '鏡'],
      ['城', '歉', '租', '鹽', '象', '肯', '它', '扔', '刀', '訴', '士'],
      ['實', '否', '單', '競', '刀', '訴', '交', '目', '生', '活', '盒'],
      ['士', '共', '支', '難', '賣', '乎', '我', '逛', '道', '烤', '美'],
      ['美', '麗', '褲', '選', '識', '登', '洗', '身', '術', '決', '她'],
      ['持', '士', '訴', '刀', '扔', '它', '肯', '象', '鹽', '租', '歉'],
      ['柿', '望', '競', '單', '難', '眼', '週', '觀', '果', '十', '拒'],
      ['打', '盒', '目', '交', '華', '麗', '色', '彩', '決', '術', '身'],
      ['目', '交', '降', '茶', '程', '所', '示', '別', '否', '實', '黃'],
    ],
    words: ['謎樣', '美麗', '華麗色彩', '生活'],
    titleText: {
      title: '您是一名明智的偵探',
      text: '您距離揭開謎底又更近一步了。',
    },
    nextLink: 'galleria-strasburgo',
  },

  'galleria-strasburgo': {
    titleText: {
      title: 'Galleria <br class="only-mobile"> Strasburgo畫廊',
      text: '一個好偵探應該能夠把各個點聯係起來。',
    },
    cta: '追蹤懸疑',
    nextLink: 'galleria-strasburgo/connects',
  },

  connects: {
    tutorial: '拖動Gancini，旋轉背景以便連接兩個部分',
    inGame: '按照您的意願在地圖上周轉，鎖定懸疑人士！',
    titleText: {
      title: '耍小聰明總是有用的！',
      text: '您距離揭開謎底更近一步了。',
    },
    nextLink: 'idroscalo',
  },

  'end-game': {
    titleText: {
      title: '還有一個迷要解開......',
      text: '成功就在眼前了！<br>點擊鏈接解開最後一個謎底，獲得您的驚喜！',
    },
    nextLink: 'https://www.ferragamo.com/enigma',
  },

  404: {
    message: '錯誤',
    cta: '返回主頁',
  },

  rotate: {
    message: '旋轉您的設備',
    thanks: '謝謝。',
  },
};
