export default {
  meta: {
    title: '大都会之谜 - Salvatore Ferragamo',
    description: '解决萨尔瓦多·菲拉格慕之谜，体验特别的惊喜。',
  },

  header: {
    back: '返回主页',
    collection: '观看 2021春夏系列',
  },

  nextGameCta: '玩下一个游戏',
  endGameCta: '解决最后的谜题',

  CookieBar: {
    code: 'ja_32701',
    link: 'https://www.ferragamo.cn/terms?key=privacy',
  },

  loader: {
    titleText: {
      title: '大都会之谜',
      text: '解决萨尔瓦多·菲拉格慕之谜，体验特别的惊喜。<br>与我们一起游逛在米兰街头，这里是由著名意大利导演Luca Guadagnino与创意总监Paul Andrew合作拍摄的独家2021春夏时尚电影的拍摄地。',
    },
    cta: '开始侦察',
  },

  idroscalo: {
    titleText: {
      title: '伊德罗斯卡洛公园',
      text: '要想解决任何谜题，最重要的是要了解你要寻找的东西是什么。<br>收集所有线索并找出答案！',
    },
    cta: '开始解决',
    nextLink: 'idroscalo/memory',
  },

  memory: {
    inGame: '记忆力和纯粹的直觉是侦探人员最可信赖的工具！',
    titleText: {
      title: '你的记忆力非常好！',
      text: '你距离解决谜题又近了一步。',
    },
    nextLink: 'via-chiossetto',
  },

  'via-chiossetto': {
    titleText: {
      title: '可奥赛托大街',
      text: '讯问嫌疑人比你想象的要棘手得多。<br>是时候看看你是否能够读懂他们了！',
    },
    cta: '寻求答案',
    nextLink: 'via-chiossetto/crosswords',
  },

  crosswords: {
    inGame: '提出正确的问题很重要，但寻求正确的答案就更重要了！',
    letters: [
      ['消', '黄', '实', '否', '别', '示', '所', '程', '茶', '降', '交'],
      ['职', '拒', '十', '果', '观', '周', '眼', '难', '单', '竞', '望'],
      ['传', '即', '车', '锻', '十', '谜', '题', '默', '漂', '包', '镜'],
      ['城', '歉', '租', '盐', '象', '肯', '它', '扔', '刀', '诉', '士'],
      ['实', '否', '单', '竞', '刀', '诉', '交', '目', '生', '活', '盒'],
      ['士', '共', '支', '难', '卖', '乎', '我', '逛', '道', '烤', '美'],
      ['美', '丽', '裤', '选', '识', '登', '洗', '身', '术', '决', '她'],
      ['持', '士', '诉', '刀', '扔', '它', '肯', '象', '盐', '租', '歉'],
      ['柿', '望', '竞', '单', '难', '眼', '周', '观', '果', '十', '拒'],
      ['打', '盒', '目', '交', '华', '丽', '色', '彩', '决', '术', '身'],
      ['目', '交', '降', '茶', '程', '所', '示', '别', '否', '实', '黄'],
    ],
    words: ['谜题', '美丽', '华丽色彩', '生活'],
    titleText: {
      title: '你是一个聪明的侦探',
      text: '你距离解决谜题又近了一步。',
    },
    nextLink: 'galleria-strasburgo',
  },

  'galleria-strasburgo': {
    titleText: {
      title: '史特拉斯堡画廊',
      text: '一个好的侦探应该能够将各个点联系起来。',
    },
    cta: '追捕嫌疑人',
    nextLink: 'galleria-strasburgo/connects',
  },

  connects: {
    tutorial: '拖动马蹄扣并旋转舞台，以连接两个部分',
    inGame: '随你意愿游转地图，并锁定可疑者！',
    titleText: {
      title: '聪明地玩游戏总是很棒的！',
      text: '你距离解决谜团更近了一步。',
    },
    nextLink: 'idroscalo',
  },

  'end-game': {
    titleText: {
      title: '还有最后一个谜题需要解决...',
      text: '就快要成功了！<br>请点击链接解决最后的谜题并获得您的惊喜！',
    },
    nextLink: 'https://www.ferragamo.com/enigma',
  },

  404: {
    message: '出了些问题',
    cta: '返回主页',
  },

  rotate: {
    message: '旋转你的设备',
    thanks: '谢谢。',
  },
};
